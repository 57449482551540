import { toast } from "react-toastify";

const initState = {
  visa: null,
  visatypes: [],
  lastVisa: "",
  addUpdateVisa: false,
  loading: false,
};

const visaReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_VISAS":
      return {
        ...state,
        visa: null,
        addUpdateVisa: false,
        visatypes: action.payload,
        lastVisa: "",
        loading: false,
      };
    case "GET_VISAS_ERROR":
      toast.error("Couldn't Load Visas");
      console.log(action.payload);
      return {
        ...state,
        loading: false,
      };
    case "GET_SINGLE_VISA":
      return {
        ...state,
        visa: action.payload,
        addUpdateVisa: false,
        lastVisa: "",
        loading: false,
      };
    case "GET_SINGLE_VISA_ERROR":
      toast.error("Couldn't Load Visa");
      console.log(action.payload);
      return {
        ...state,
        loading: false,
        addUpdateVisa: false,
        lastVisa: "",
      };
    case "UPDATE_VISA_STATUS":
      return {
        ...state,
        visatypes: state.visatypes.filter((n) => {
          if (n.id === action.payload) {
            n.status = !n.status;
          }
          return n.id !== 123;
        }),
        visa: null,
        addUpdateVisa: false,
        lastVisa: "",
        loading: false,
      };
    case "UPDATE_VISA_STATUS_ERROR":
      toast.error("Couldn't Update Status");
      console.log(action.payload);
      return {
        ...state,
        visa: null,
        addUpdateVisa: false,
        lastVisa: "",
        loading: false,
      };

    case "ADD_UPDATE_VISA":
      window.alert("Successfully Submitted");
      return {
        ...state,
        visa: null,
        addUpdateVisa: true,
        lastVisa: "",
        loading: false,
      };
    case "ADD_UPDATE_VISA_ERROR":
      toast.error("Couldn't Add/Update Visa");
      console.log(action.payload);
      return {
        ...state,
        loading: false,
      };
    case "GET_LAST_VISA":
      return {
        ...state,
        loading: false,
        lastVisa: action.payload,
      };
    case "GET_LAST_VISA_ERROR":
      return {
        ...state,
        loading: false,
      };
    case "LOADING_VISA":
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default visaReducer;
