import { toast } from "react-toastify";

const initState = {
  hotelApplications: [],
  loading: false,
};

const hotelApplicationReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_HOTEL_APPLICATIONS":
      return {
        ...state,
        hotelApplications: action.payload,
        loading: false,
      };
    case "GET_HOTEL_APPLICATIONS_ERROR":
      toast.error("Couldn't Load Bookings");
      console.log(action.payload);
      return {
        ...state,
        loading: false,
      };
    case "UPDATE_HOTEL_APP_STATUS":
      return {
        ...state,
        hotelApplications: state.hotelApplications.filter((n) => {
          if (n.id === action.payload.id) {
            n.status = action.payload.status;
          }
          return n.id !== 123;
        }),
        hotel: null,
        loading: false,
      };
    case "UPDATE_HOTEL_APP_STATUS_ERROR":
      toast.error("Couldn't Update Status");
      console.log(action.payload);
      return {
        ...state,
        hotel: null,
        loading: false,
      };
    case "LOADING_HOTEL_APPLICATION":
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default hotelApplicationReducer;
