import { toast } from "react-toastify";

const initState = {
  stats: [],
  visa: [],
  hotel: [],
  loading: false,
};

const statReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_STATS":
      return {
        ...state,
        stats: action.payload.stats,
        hotel: action.payload.hotel,
        visa: action.payload.visa,
        loading: false,
      };
    case "GET_STATS_ERROR":
      toast.error("Couldn't Load Stats");
      console.log(action.payload);
      return {
        ...state,
        loading: false,
      };

    case "LOADING_STATS":
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default statReducer;
