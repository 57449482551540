import React, { Component } from "react";

import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import Loading from "../widgets/Loading";
import {
  GetSingleUser,
  UploadUserPicture,
  AddUser,
  UpdateUser,
} from "../../store/actions/userActions";
import { isFileImage, countries } from "../../functions/util";
import { toast } from "react-toastify";
class AddEditUser extends Component {
  state = {
    image: null,
    changeimage: false,
    country: "Madagascar",
    fname: "",
    lname: "",
    password: "",
    cpassword: "",
    phone: "",
    email: "",
    originalimage: "",
    submitted: true,
  };

  componentDidMount() {
    document.title = "Add/Update User";
    if (this.props.match.params.id) {
      this.props.getSingleUser(this.props.match.params.id);
    }
  }

  componentDidUpdate(p, c) {
    if (
      p.user.loading === true &&
      this.props.user.loading === false &&
      this.props.user.addUpdateUser === false
    ) {
      this.setState({
        fname: this.props.user.user.fname,
        lname: this.props.user.user.lname,
        country: this.props.user.user.country,
        phone: this.props.user.user.phone,
        email: this.props.user.user.email,
        originalimage: this.props.user.user.image,
      });
    }
    if (
      this.state.submitted &&
      this.props.user.userimage !== "" &&
      p.user.userimage === ""
    ) {
      const user = {
        fname: this.state.fname,
        lname: this.state.lname,
        country: this.state.country,
        phone: this.state.phone,
        email: this.state.email,
        image: this.state.originalimage,
        password: this.state.password,
        id: "",
      };
      if (this.props.match.params.id === undefined) {
        user.image = this.props.user.userimage;
        this.props.addUser(user);
      } else {
        if (this.state.changeimage) {
          user.image = this.props.user.userimage;
        }
        //window.alert("Update Aritcle ");
        this.props.updateUser(this.props.match.params.id, user);
      }
    }
    if (
      p.user.addUpdateUser === false &&
      this.props.user.addUpdateUser === true
    ) {
      window.location = "/users";
    }
  }

  handleChange = (e) => {
    if (e.target.files[0]) {
      this.setState({
        image: e.target.files[0],
        changeimage: true,
      });
    }
  };

  cancelImage = () => {
    this.setState({
      changeimage: false,
      image: null,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const user = {
      fname: this.state.fname,
      lname: this.state.lname,
      country: this.state.country,
      phone: this.state.phone,
      email: this.state.email,
      image: this.state.originalimage,
      password: this.state.password,
      id: "",
      cpassword: this.state.cpassword,
    };
    if (
      this.state.password !== this.state.cpassword &&
      this.props.match.params.id === undefined
    ) {
      window.alert("Passwords don't match!");
    } else if (
      !isFileImage(this.state.image) &&
      this.state.originalimage === ""
    ) {
      toast.error("File is Not Appropriate!");
    } else {
      if (this.props.match.params.id !== undefined) {
        user.id = this.props.match.params.id;
        if (this.state.changeimage) {
          this.props.uploadUserPicture(this.state.image);
        } else {
          //window.alert("Update Aritcle No Image");

          this.props.updateUser(this.props.match.params.id, user);
        }
      } else {
        this.props.uploadUserPicture(this.state.image);
      }
    }
  };
  render() {
    return (
      <Container fluid>
        <Row>
          {this.props.match.params.id !== undefined ? (
            <h3>Edit User</h3>
          ) : (
            <h3>Add User</h3>
          )}
        </Row>
        <Row>
          <Col lg="12">
            {this.props.user.loading === true ? (
              <Loading />
            ) : (
              <Form className="mt-3 " onSubmit={this.handleSubmit}>
                <Container>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          cypressid="title"
                          type="text"
                          pattern="^(?=.*[a-zA-Z]).{5,50}$"
                          value={this.state.fname}
                          onChange={(val) => {
                            this.setState({
                              fname: val.target.value,
                            });
                          }}
                          placeholder="Enter First Name"
                        />
                      </Form.Group>
                      <br />

                      <Form.Group>
                        <Form.Label>Country</Form.Label>
                        <Form.Select
                          value={this.state.country}
                          onChange={(val) => {
                            try {
                              this.setState({
                                country: val.target.value,
                              });
                            } catch (e) {
                              alert("Wrong Value");
                            }
                          }}
                        >
                          {countries.map((c) => (
                            <option value={c}>{c}</option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                      <br />
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          cypressid="title"
                          type="text"
                          minLength={1}
                          value={this.state.lname}
                          onChange={(val) => {
                            this.setState({
                              lname: val.target.value,
                            });
                          }}
                          placeholder="Enter Last Name"
                        />
                      </Form.Group>
                      <br />
                      <Form.Group>
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                          cypressid="title"
                          type="tel"
                          minLength={6}
                          pattern="^\+(?:[0-9]●?){6,14}[0-9]$"
                          value={this.state.phone}
                          onChange={(val) => {
                            this.setState({
                              phone: val.target.value,
                            });
                          }}
                          placeholder="Enter Phone"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Container>
                <br />
                <hr />
                <br />
                <Container>
                  <Row>
                    <Col>
                      {this.props.match.params.id === undefined ? (
                        <>
                          <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              cypressid="Email"
                              placeholder="Enter Email"
                              minLength={6}
                              value={this.state.email}
                              required
                              type="email"
                              onChange={(value) => {
                                this.setState({
                                  email: value.target.value,
                                });
                              }}
                            />
                          </Form.Group>
                          <br />
                          <Form.Group>
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                              cypressid="password"
                              required
                              value={this.state.password}
                              minLength={8}
                              pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
                              type="password"
                              onChange={(value) => {
                                this.setState({
                                  password: value.target.value,
                                });
                              }}
                              placeholder="Min of 8 Characters, At least 1 Capital, 1 Special Character, and 1 Number"
                            />
                          </Form.Group>
                          <br />
                          <Form.Group>
                            <Form.Label>Confirm password</Form.Label>
                            <Form.Control
                              cypressid="Cpassword"
                              required
                              value={this.state.cpassword}
                              minLength={8}
                              pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
                              type="password"
                              onChange={(value) => {
                                this.setState({
                                  cpassword: value.target.value,
                                });
                              }}
                              placeholder="Confirm Password"
                            />
                          </Form.Group>
                          <br />
                        </>
                      ) : (
                        <>
                          <h5>Email: {this.state.email}</h5>
                          <br />
                        </>
                      )}
                    </Col>
                    <Col>
                      <Row>
                        <Col lg="6">
                          <Form.Group controlId="formFile">
                            <Form.Label>Insert </Form.Label>
                            <Form.Control
                              cypressid="image"
                              onChange={this.handleChange}
                              type="file"
                              accept="image/*"
                            />
                          </Form.Group>
                        </Col>
                        <Col lg="6" className="cancelimagecol">
                          <Button
                            onChange={this.cancelImage}
                            className="cancelimagebtn"
                            variant="warning"
                          >
                            Cancel Image
                          </Button>
                        </Col>
                      </Row>
                      <br />
                      <hr />
                      <br />
                      <Form.Group>
                        <Button
                          cypressid="submit"
                          variant="info"
                          type="submit"
                          size="large"
                          style={{ width: "100%" }}
                          //onClick={this.handleSubmit}
                        >
                          Submit
                        </Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </Container>
              </Form>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    firebaseauth: state.firebase.auth,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSingleUser: (id) => dispatch(GetSingleUser(id)),
    uploadUserPicture: (image) => dispatch(UploadUserPicture(image)),
    addUser: (doc) => dispatch(AddUser(doc)),
    updateUser: (id, doc) => dispatch(UpdateUser(id, doc)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditUser);
