import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import { Container, Button, Row, Form, Col, Modal } from "react-bootstrap";

import { Icon } from "rsuite";
import {
  GetUsers,
  DeleteUser,
  UpdateUserStatus,
} from "../../store/actions/userActions";
import Loading from "../widgets/Loading";
import { connect } from "react-redux";

class Users extends Component {
  constructor(props) {
    document.title = "Users";
    super(props);
    this.state = {
      usersData: {
        columns: [],
        rows: [],
      },
      deleteModal: false,
      showModal: false,
      selectedUser: null,
      sucessmessage: null,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleInputClose = this.handleInputClose.bind(this);
  }

  handleDelete = (news) => {
    this.setState({
      selectedUser: news,
      deleteModal: true,
    });
  };

  handleConfirm = () => {
    this.props.deleteUser(this.state.selectedUser.id);
    this.setState({
      selectedUser: null,
      deleteModal: false,
      showModal: false,
    });
  };

  handleConfirmStatus = () => {
    this.props.updateUserStatus(
      this.state.selectedUser.id,
      !this.state.selectedUser.status
    );
    this.setState({
      selectedUser: null,
      deleteModal: false,
      showModal: false,
    });
  };
  handleInputClose = () => {
    this.setState({
      deleteModal: false,
      showModal: false,
    });
  };
  componentDidMount() {
    this.props.getUsers();
  }
  componentDidUpdate(pr, cr) {
    if (
      pr.user.loading === false &&
      this.props.user.loading === false &&
      pr.user.users !== this.props.user.users
    ) {
      this.setState({
        usersData: {
          columns: [
            {
              label: "Picture",
              field: "image",
            },
            {
              label: "First Name",
              field: "fname",
              sort: "asc",
            },
            {
              label: "Last Name",
              field: "lname",
              sort: "asc",
            },
            {
              label: "Phone",
              field: "phone",
              sort: "asc",
            },
            {
              label: "Country",
              field: "country",
              sort: "asc",
            },
            {
              label: "Status",
              field: "status",
              sort: "asc",
              width: 50,
            },

            {
              label: "Edit",
              field: "edit",
              sort: "asc",
              width: 50,
            },

            {
              label: "Delete",
              field: "delete",
              sort: "asc",
              width: 50,
            },
          ],
          rows: this.props.user.users.map((row) => {
            return {
              ...row,
              image: (
                <img
                  src={row.image}
                  alt={row.fname}
                  style={{
                    height: "30px",
                    width: "30px",
                    borderRadius: "50%",
                  }}
                />
              ),
              status: (
                <>
                  <Form.Switch
                    type="switch"
                    id="active"
                    style={{ marginLeft: "2em" }}
                    checked={row.status}
                    onChange={() => {
                      this.setState({
                        selectedUser: row,
                        showModal: true,
                      });
                    }}
                  />
                </>
              ),
              edit: (
                <>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#e0ac1d",
                      color: "black",
                      fontSize: "0.25rem",
                    }}
                    onClick={() => {
                      window.open("/edituser/" + row.id, "_blank");
                    }}
                  >
                    <Icon icon="pencil" />
                  </Button>
                </>
              ),
              delete: (
                <>
                  <Button
                    id="delete"
                    variant="contained"
                    style={{
                      backgroundColor: "#B00020",
                      color: "white",
                      fontSize: "0.25rem",
                    }}
                    onClick={() => {
                      this.handleDelete(row);
                    }}
                  >
                    <Icon icon="trash" />
                  </Button>
                </>
              ),
            };
          }),
        },
      });
    } else if (pr.user.loading === true && this.props.user.loading === false) {
      this.setState({
        usersData: {
          columns: [
            {
              label: "Picture",
              field: "image",
            },
            {
              label: "First Name",
              field: "fname",
              sort: "asc",
            },
            {
              label: "Last Name",
              field: "lname",
              sort: "asc",
            },
            {
              label: "Phone",
              field: "phone",
              sort: "asc",
            },
            {
              label: "Country",
              field: "country",
              sort: "asc",
            },
            {
              label: "Status",
              field: "status",
              sort: "asc",
              width: 50,
            },

            {
              label: "Edit",
              field: "edit",
              sort: "asc",
              width: 50,
            },

            {
              label: "Delete",
              field: "delete",
              sort: "asc",
              width: 50,
            },
          ],
          rows: this.props.user.users.map((row) => {
            return {
              ...row,
              image: (
                <img
                  src={row.image}
                  style={{
                    height: "30px",
                    width: "30px",
                    borderRadius: "50%",
                  }}
                  alt={row.fname}
                />
              ),
              status: (
                <>
                  <Form.Switch
                    type="switch"
                    id="active"
                    style={{ marginLeft: "2em" }}
                    checked={row.status}
                    onChange={() => {
                      this.setState({
                        selectedUser: row,
                        showModal: true,
                      });
                    }}
                  />
                </>
              ),
              edit: (
                <>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#e0ac1d",
                      color: "black",
                      fontSize: "0.25rem",
                    }}
                    onClick={() => {
                      window.open("/edituser/" + row.id, "_blank");
                    }}
                  >
                    <Icon icon="pencil" />
                  </Button>
                </>
              ),
              delete: (
                <>
                  <Button
                    id="delete"
                    variant="contained"
                    style={{
                      backgroundColor: "#B00020",
                      color: "white",
                      fontSize: "0.25rem",
                    }}
                    onClick={() => {
                      this.handleDelete(row);
                    }}
                  >
                    <Icon icon="trash" />
                  </Button>
                </>
              ),
            };
          }),
        },
      });
    }
  }

  render() {
    return (
      <div>
        <Container fluid>
          <Row>
            <Col lg="11">
              <h3>
                Users :{" "}
                {this.props.user.users !== undefined &&
                this.props.user.users !== null &&
                this.props.user.users.length !== 0
                  ? this.props.user.users.length
                  : ""}
              </h3>
            </Col>
            <Col>
              <Button
                variant="primary"
                style={{ marginTop: "25px" }}
                onClick={() => {
                  window.open("/adduser", "_blank");
                }}
              >
                Add
              </Button>
            </Col>
          </Row>
          {this.props.user.loading ? (
            <Loading />
          ) : (
            <MDBDataTable
              cypressid="table"
              striped
              bordered
              hover
              responsive
              data={this.state.usersData}
            />
          )}
          <Modal
            style={{ opacity: 1 }}
            show={this.state.deleteModal}
            onHide={this.handleInputClose}
            animation={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are You Sure to Delete?{" "}
              {this.state.selectedUser && this.state.selectedUser.name_en}
            </Modal.Body>
            <Modal.Footer>
              <Button
                cypressid="cancel_delete"
                variant="secondary"
                onClick={this.handleInputClose}
              >
                Cancel
              </Button>
              <Button
                cypressid="confirm_delete"
                variant="contained"
                style={{ backgroundColor: "#B00020", color: "white" }}
                onClick={this.handleConfirm}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            style={{ opacity: 1 }}
            show={this.state.showModal}
            animation={false}
            onHide={this.handleInputClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are You Sure to Change Status of{" "}
              {this.state.selectedUser &&
                this.state.selectedUser.fname +
                  " " +
                  this.state.selectedUser.lname}
              ?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleInputClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: "#B00020", color: "white" }}
                onClick={this.handleConfirmStatus}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    firebaseauth: state.firebase.auth,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: () => dispatch(GetUsers()),
    deleteUser: (id) => dispatch(DeleteUser(id)),
    updateUserStatus: (id, decision) =>
      dispatch(UpdateUserStatus(id, decision)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
