import { toast } from "react-toastify";

const initState = {
  profile:
    localStorage.getItem("authprofile") === "undefined"
      ? null
      : JSON.parse(localStorage.getItem("authprofile")),
  loading: false,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "SIGN_IN":
      localStorage.removeItem("authprofile");
      return {
        ...state,
        profile: null,
        loading: false,
      };
    case "SAVE_PROFILE":
      localStorage.setItem("authprofile", JSON.stringify(action.payload));
      return {
        ...state,
        profile: action.payload,
      };
    case "SIGN_IN_ERROR":
      toast.error(action.payload.toString());
      return {
        ...state,
        loading: false,
      };
    case "SIGN_OUT":
      localStorage.removeItem("authprofile");
      window.location = "/";
      return {
        ...state,
        profile: null,
        loading: false,
      };
    case "SIGN_OUT_ERROR":
      toast.error(action.payload.toString());
      return {
        ...state,
        loading: false,
      };
    case "RESET_PASSWORD":
      toast.success("Password Reset Email Sent");
      return {
        ...state,
        loading: false,
      };
    case "RESET_PASSWORD_ERROR":
      console.log(action.payload);
      toast.error(action.payload.toString());
      return {
        ...state,
        loading: false,
      };

    case "UPDATE_PROFILE":
      toast.success("Profile Updated");
      window.location = "/profile";
      return {
        ...state,
        loading: false,
      };
    case "UPDATE_PROFILE_ERROR":
      toast.error("Couldn't Update Profile");
      console.log(action.payload);
      return {
        ...state,
        loading: false,
      };
    case "LOADING_AUTH":
      return { ...state, loading: true };
    default:
      return state;
  }
};

export default authReducer;
