import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route } from "react-router-dom";
import store from "./store/store";

import { ReactReduxFirebaseProvider, isLoaded } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import firebase from "./config/firebaseConfig";
import { ToastContainer } from "react-toastify";
import { connect, useSelector } from "react-redux";
import React, { Component } from "react";
import { SaveProfile, SignOut } from "./store/actions/authActions";
import Loading from "./components/widgets/Loading";
import AdminWrapper from "./components/main/AdminWrapper";
import Login from "./components/auth/Login";
import OfficeWrapper from "./components/main/OfficeWrapper";

class App extends Component {
  state = {};
  componentDidUpdate(prev, curr) {
    if (this.props.auth.profile === null) {
      if (
        prev.profile.isEmpty === true &&
        this.props.profile.isEmpty === false
      ) {
        if (this.props.profile.status === true) {
          this.props.saveProfile();
        }
      }
    }
  }
  render() {
    const auth = this.props.firebaseauth;
    const profile = this.props.profile;
    return (
      <ReactReduxFirebaseProvider {...rrfProps}>
        <BrowserRouter>
          <AuthIsLoaded>
            <ToastContainer autoClose={3000} hideProgressBar={true} />
            <div>
              {(auth === null && auth.uid) || !profile.isLoaded ? (
                <Loading />
              ) : !profile.isEmpty &&
                profile.isLoaded &&
                profile.status === true ? (
                profile.type === 1 ? (
                  <Route path="/" component={AdminWrapper} />
                ) : profile.type === 2 && this.props.auth.profile !== null ? (
                  <Route path="/" component={OfficeWrapper} />
                ) : (
                  <Loading />
                )
              ) : (
                <Route path="/" component={Login} />
              )}
            </div>
          </AuthIsLoaded>
        </BrowserRouter>
      </ReactReduxFirebaseProvider>
    );
  }
}
const rrfProps = {
  firebase,
  config: {
    userProfile: "admin",
    useFirestoreForProfile: true,
  },
  dispatch: store.dispatch,
  createFirestoreInstance,
};

const mapStateToProps = (state) => {
  return {
    firebaseauth: state.firebase.auth,
    auth: state.auth,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(SignOut()),
    saveProfile: () => dispatch(SaveProfile()),
  };
};

function AuthIsLoaded({ children }) {
  const auth = useSelector((state) => state.firebase.auth);
  if (!isLoaded(auth)) return <Loading />;
  return children;
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
