import { toast } from "react-toastify";

export const GetVisas = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to the database
    try {
      dispatch(StartLoadingVisa());
      const firestore = getFirestore();

      firestore
        .collection("visatypes")
        .orderBy("duration", "asc")
        .get()
        .then((data) => {
          const datalist = [];
          data.forEach((element) => {
            datalist.push({
              ...element.data(),
              id: element.id,
            });
          });
          dispatch({
            type: "GET_VISAS",
            payload: datalist,
          });
        })
        .catch((e) => {
          dispatch({ type: "GET_VISAS_ERROR", payload: e });
        });
    } catch (err) {
      dispatch({ type: "GET_VISAS_ERROR", payload: err });
    }
  };
};

export const GetSingleVisa = (id) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to the database
    try {
      dispatch(StartLoadingVisa());
      const firestore = getFirestore();
      firestore
        .collection("visatypes")
        .doc(id)
        .get()
        .then((doc) => {
          dispatch({
            type: "GET_SINGLE_VISA",
            payload: { ...doc.data(), id: id },
          });
        })
        .catch((e) => {
          dispatch({ type: "GET_SINGLE_VISA_ERROR", payload: e });
        });
    } catch (err) {
      dispatch({ type: "GET_SINGLE_VISA_ERROR", payload: err });
    }
  };
};

export const GetLastVisa = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to the database
    try {
      dispatch(StartLoadingVisa());
      const firestore = getFirestore();
      firestore
        .collection("visatypes")
        .orderBy("created_at", "desc")
        .limit(1)
        .get()
        .then((data) => {
          var id = "";
          data.forEach((element) => {
            id = element.id;
          });
          dispatch({
            type: "GET_LAST_VISA",
            payload: id,
          });
        })
        .catch((e) => {
          dispatch({ type: "GET_LAST_VISA_ERROR", payload: e });
        });
    } catch (err) {
      dispatch({ type: "GET_LAST_VISA_ERROR", payload: err });
    }
  };
};

export const UpdateVisaStatus = (id, decision) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      dispatch(StartLoadingVisa());
      // make async call to the database
      const firestore = getFirestore();
      firestore
        .collection("visatypes")
        .doc(id)
        .update({
          status: decision,
        })
        .then(() => {
          dispatch({ type: "UPDATE_VISA_STATUS", payload: id });
        })
        .catch((e) => {
          dispatch({ type: "UPDATE_VISA_STATUS_ERROR", payload: e });
        });
    } catch (err) {
      dispatch({ type: "UPDATE_VISA_STATUS_ERROR", payload: err });
    }
  };
};

export const AddVisa = (visa) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to the database
    try {
      dispatch(StartLoadingVisa());
      const firestore = getFirestore();
      firestore
        .collection("visatypes")
        .doc(visa.id)
        .get()
        .then((doc) => {
          if (doc.exists) {
            toast.error("A Visa Already Exisits by " + visa.id);
            dispatch({
              type: "ADD_UPDATE_VISA_ERROR",
              payload: "A Visa Already Exisits by " + visa.id,
            });
          } else {
            firestore
              .collection("visatypes")
              .doc(visa.id)
              .set({
                updated_at: new Date(),
                created_at: new Date(),
                applications: 0,
                description: visa.description,
                duration: visa.duration,
                image:
                  "https://firebasestorage.googleapis.com/v0/b/emiraevisa-278d8.appspot.com/o/visas%2Fuaegovtlogo.png?alt=media&token=52f88a29-07c6-47c8-9314-028e4baff0fd",
                entry: visa.entry,
                note: visa.note,
                price: parseInt(visa.price),
                status: true,
              })
              .then(() => {
                dispatch({ type: "ADD_UPDATE_VISA" });
              })
              .catch((err) => {
                dispatch({ type: "ADD_UPDATE_VISA_ERROR", payload: err });
                console.log("Transaction failure:", err);
              });
          }
        })
        .catch((e) => {
          dispatch({ type: "ADD_UPDATE_VISA_ERROR", payload: e });
        });
    } catch (err) {
      dispatch({ type: "ADD_UPDATE_VISA_ERROR", payload: err });
    }
  };
};

export const UpdateVisa = (id, visa) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to the database
    try {
      dispatch(StartLoadingVisa());
      const firestore = getFirestore();
      firestore
        .collection("visatypes")
        .doc(id)
        .update({
          updated_at: new Date(),
          description: visa.description,
          duration: visa.duration,
          entry: visa.entry,
          note: visa.note,
          price: parseInt(visa.price),
        })
        .then(() => {
          dispatch({ type: "ADD_UPDATE_VISA", payload: visa });
        })
        .catch((err) => {
          dispatch({ type: "ADD_UPDATE_VISA_ERROR", payload: err });
          console.log("Transaction failure:", err);
        });
    } catch (err) {
      dispatch({ type: "ADD_UPDATE_VISA_ERROR", payload: err });
    }
  };
};

export const StartLoadingVisa = () => {
  return {
    type: "LOADING_VISA",
  };
};
