import { storage } from "../../config/firebaseConfig";
import { secondaryApp } from "../../config/firebaseConfig";

export const GetUsers = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to the database
    try {
      dispatch(StartLoadingUser());
      const firestore = getFirestore();

      firestore
        .collection("admin")
        .orderBy("created_at", "asc")
        .get()
        .then((user) => {
          const userlist = [];
          user.forEach((element) => {
            userlist.push({
              ...element.data(),
              id: element.id,
            });
          });
          dispatch({ type: "GET_USERS", payload: userlist });
        })
        .catch((e) => {
          dispatch({ type: "GET_USERS_ERROR", payload: e });
        });
    } catch (err) {
      dispatch({ type: "GET_USERS_ERROR", payload: err });
    }
  };
};

export const GetSingleUser = (id) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to the database
    try {
      dispatch(StartLoadingUser());
      const firestore = getFirestore();
      firestore
        .collection("admin")
        .doc(id)
        .get()
        .then((doc) => {
          dispatch({ type: "GET_SINGLE_USER", payload: doc.data() });
        })
        .catch((e) => {
          dispatch({ type: "GET_SINGLE_USER_ERROR", payload: e });
        });
    } catch (err) {
      dispatch({ type: "GET_SINGLE_USER_ERROR", payload: err });
    }
  };
};

export const UploadUserPicture = (picture) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to the database
    try {
      dispatch(StartLoadingUser());
      const uploadTask = storage.ref("users/" + picture.name).put(picture);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          dispatch({
            type: "UPLOAD_USER_PICTURE_ERROR",
            payload: error,
          });
        },
        () => {
          storage
            .ref("/users")
            .child(picture.name)
            .getDownloadURL()
            .then((url) => {
              dispatch({
                type: "UPLOAD_USER_PICTURE",
                payload: url,
              });
            });
        }
      );
    } catch (err) {
      dispatch({
        type: "UPLOAD_USER_PICTURE_ERROR",
        payload: err,
      });
    }
  };
};

export const UpdateUser = (id, user) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to the database
    try {
      dispatch(StartLoadingUser());
      console.log(user);
      const firestore = getFirestore();
      firestore
        .collection("admin")
        .doc(id)
        .update({
          updated_at: new Date(),
          fname: user.fname,
          lname: user.lname,
          country: user.country,
          phone: user.phone,
          image: user.image,
        })
        .then(() => {
          dispatch({ type: "ADD_UPDATE_USER", payload: user });
        })
        .catch((e) => {
          dispatch({ type: "ADD_UPDATE_USER_ERROR", payload: e });
        });
    } catch (err) {
      dispatch({ type: "ADD_UPDATE_USER_ERROR", payload: err });
    }
  };
};

export const AddUser = (user) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to the database
    try {
      dispatch(StartLoadingUser());
      const firestore = getFirestore();
      secondaryApp
        .auth()
        .createUserWithEmailAndPassword(user.email, user.password)
        .then((res) => {
          return firestore.collection("admin").doc(res.user.uid).set({
            updated_at: new Date(),
            created_at: new Date(),
            fname: user.fname,
            status: true,
            email: user.email,
            lname: user.lname,
            country: user.country,
            phone: user.phone,
            image: user.image,
            type: 2,
          });
        })
        .then(() => {
          secondaryApp.auth().signOut();
        })

        .then(() => {
          dispatch({ type: "ADD_UPDATE_USER" });
        })
        .catch((err) => {
          dispatch({ type: "ADD_UPDATE_USER_ERROR", payload: err });
          console.log("Transaction failure:", err);
        });
    } catch (err) {
      dispatch({ type: "ADD_UPDATE_USER_ERROR", payload: err });
    }
  };
};

export const UpdateUserStatus = (id, decision) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      dispatch(StartLoadingUser());
      // make async call to the database
      const firestore = getFirestore();
      firestore
        .collection("admin")
        .doc(id)
        .update({
          status: decision,
        })
        .then(() => {
          dispatch({ type: "UPDATE_USER_STATUS", payload: id });
        })
        .catch((e) => {
          dispatch({ type: "UPDATE_USER_STATUS_ERROR", payload: e });
        });
    } catch (err) {
      dispatch({ type: "UPDATE_USER_STATUS_ERROR", payload: err });
    }
  };
};

export const DeleteUser = (id) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to the database
    try {
      dispatch(StartLoadingUser());
      const firestore = getFirestore();
      firestore
        .collection("admin")
        .doc(id)
        .delete()
        .then(() => {
          dispatch({ type: "DELETE_USER", payload: id });
        })
        .catch((e) => {
          dispatch({ type: "DELETE_USER_ERROR", payload: e });
        });
    } catch (err) {
      dispatch({ type: "DELETE_USER_ERROR", payload: err });
    }
  };
};

export const StartLoadingUser = () => {
  return {
    type: "LOADING_USER",
  };
};
