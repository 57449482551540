import { toast } from "react-toastify";

const initState = {
  faq: null,
  addUpdateFaq: false,
  faqs: [],
  loading: false,
};

const faqReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_FAQS":
      return {
        ...state,
        faq: null,
        addUpdateFaq: false,
        faqs: action.payload,
        loading: false,
      };
    case "GET_FAQS_ERROR":
      toast.error("Couldn't Load FAQs");
      console.log(action.payload);
      return {
        ...state,
        loading: false,
      };
    case "DELETE_FAQ":
      return {
        ...state,
        faqs: state.faqs.filter((n) => {
          return n.id !== action.payload;
        }),
        faq: null,
        addUpdateFaq: false,
        loading: false,
      };
    case "DELETE_FAQ_ERROR":
      toast.error("Couldn't Delete FAQ");
      console.log(action.payload);
      return {
        ...state,
        faq: null,
        loading: false,
      };
    case "GET_SINGLE_FAQ":
      return {
        ...state,
        faq: action.payload,
        addUpdateFaq: false,
        loading: false,
      };
    case "GET_SINGLE_FAQ_ERROR":
      toast.error("Couldn't Load Faq");
      console.log(action.payload);
      return {
        ...state,
        loading: false,
      };

    case "ADD_UPDATE_FAQ":
      window.alert("Successfully Submitted");
      return {
        ...state,
        faq: null,
        addUpdateFaq: true,
        loading: false,
      };
    case "ADD_UPDATE_FAQ_ERROR":
      toast.error("Couldn't Add/Update Faq");
      console.log(action.payload);
      return {
        ...state,
        loading: false,
      };
    case "LOADING_FAQ":
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default faqReducer;
