import React, { Component } from "react";

import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { addLeadingZeros } from "../../functions/util";

import Loading from "../widgets/Loading";
import {
  GetSingleFaq,
  AddFaq,
  UpdateFaq,
} from "../../store/actions/faqActions";

class AddEditFaq extends Component {
  state = {
    question: "",
    answer: "",
    id: "",
    submitted: true,
  };

  componentDidMount() {
    document.title = "Add/Update Faq";
    if (this.props.match.params.id) {
      this.props.getSingleFaq(this.props.match.params.id);
    }
  }

  componentDidUpdate(p, c) {
    console.log("change");
    console.log(p.faq.addUpdateFaq);
    console.log(this.props.faq.addUpdateFaq);
    if (
      p.faq.loading === true &&
      this.props.faq.loading === false &&
      this.props.faq.addUpdateFaq === false &&
      this.props.faq.faq !== null
    ) {
      this.setState({
        question: this.props.faq.faq.question,
        answer: this.props.faq.faq.answer,
        id:
          this.props.match.params.id !== undefined
            ? this.props.match.params.id
            : "",
      });
    }
    if (p.faq.addUpdateFaq === false && this.props.faq.addUpdateFaq === true) {
      window.location = "/faqs";
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const faq = {
      question: this.state.question,
      answer: this.state.answer,
      id: this.state.id,
    };

    if (this.props.match.params.id !== undefined) {
      faq.id = this.props.match.params.id;
      this.props.updateFaq(this.props.match.params.id, faq);
    } else {
      this.props.addFaq(faq);
    }
  };
  render() {
    return (
      <Container fluid>
        <Row>
          <h3>
            {this.props.match.params.id !== undefined ? (
              <h3>Edit Faq</h3>
            ) : (
              <h3>Add Faq</h3>
            )}
          </h3>
        </Row>
        <Row>
          <Col lg="12">
            {this.props.faq.loading === true ? (
              <Loading />
            ) : (
              <Form className="mt-3 " onSubmit={this.handleSubmit}>
                <Container>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Question</Form.Label>
                        <Form.Control
                          as="textarea"
                          value={this.state.question}
                          onChange={(val) => {
                            this.setState({
                              question: val.target.value,
                            });
                          }}
                          style={{ height: "10vh" }}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Answer</Form.Label>
                        <Form.Control
                          as="textarea"
                          value={this.state.answer}
                          onChange={(val) => {
                            this.setState({
                              answer: val.target.value,
                            });
                          }}
                          style={{ height: "20vh" }}
                        />
                      </Form.Group>

                      <br />
                    </Col>
                  </Row>
                </Container>
                <br />
                <Container>
                  <Row>
                    <Col></Col>
                    <Col>
                      <hr />
                      <br />
                      <Form.Group>
                        <Button
                          cypressid="submit"
                          variant="info"
                          type="submit"
                          size="large"
                          style={{ width: "100%" }}
                          //onClick={this.handleSubmit}
                        >
                          Submit
                        </Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </Container>
              </Form>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    firebaseauth: state.firebase.auth,
    faq: state.faq,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSingleFaq: (id) => dispatch(GetSingleFaq(id)),
    addFaq: (doc) => dispatch(AddFaq(doc)),
    updateFaq: (id, doc) => dispatch(UpdateFaq(id, doc)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditFaq);
