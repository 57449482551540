import React, { Component } from "react";

import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { addLeadingZeros } from "../../functions/util";

import Loading from "../widgets/Loading";
import {
  GetSingleVisa,
  AddVisa,
  UpdateVisa,
  GetLastVisa,
} from "../../store/actions/visaActions";

class AddEditVisa extends Component {
  state = {
    description: "",
    duration: "",
    entry: "Single Entry Visa",
    note: "Insurance Included",
    price: 0,
    id: "",
    submitted: true,
  };

  componentDidMount() {
    document.title = "Add/Update Visa";
    if (this.props.match.params.id) {
      this.props.getSingleVisa(this.props.match.params.id);
    } else {
      this.props.getLastVisa();
    }
  }

  componentDidUpdate(p, c) {
    if (
      p.visa.loading === true &&
      this.props.visa.loading === false &&
      this.props.visa.addUpdateVisa === false &&
      this.props.visa.visa !== null
    ) {
      this.setState({
        description: this.props.visa.visa.description,
        duration: this.props.visa.visa.duration,
        entry: this.props.visa.visa.entry,
        note: this.props.visa.visa.note,
        price: this.props.visa.visa.price,
        id:
          this.props.match.params.id !== undefined
            ? this.props.match.params.id
            : "",
      });
    }
    if (
      p.visa.addUpdateVisa === false &&
      this.props.visa.addUpdateVisa === true
    ) {
      window.location = "/visas";
    }
    if (p.visa.loading && p.visa.lastVisa !== this.props.visa.lastVisa) {
      try {
        this.setState({
          id:
            "uaevisa" +
            addLeadingZeros(
              parseInt(this.props.visa.lastVisa.substring(7)) + 1,
              5
            ),
        });
      } catch (e) {
        // Do Nothing
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const visa = {
      description: this.state.description,
      duration: this.state.duration,
      entry: this.state.entry,
      note: this.state.note,
      price: this.state.price,
      id: this.state.id,
    };

    if (this.props.match.params.id !== undefined) {
      visa.id = this.props.match.params.id;

      this.props.updateVisa(this.props.match.params.id, visa);
    } else {
      this.props.addVisa(visa);
    }
  };
  render() {
    return (
      <Container fluid>
        <Row>
          <h3>
            {this.props.match.params.id !== undefined ? (
              <h3>Edit Visa</h3>
            ) : (
              <h3>Add Visa</h3>
            )}
          </h3>
        </Row>
        <Row>
          <Col lg="12">
            {this.props.visa.loading === true ? (
              <Loading />
            ) : (
              <Form className="mt-3 " onSubmit={this.handleSubmit}>
                <Container>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Duration</Form.Label>
                        <Form.Control
                          type="text"
                          pattern="^(?=.*[a-zA-Z]).{5,50}$"
                          value={this.state.duration}
                          onChange={(val) => {
                            this.setState({
                              duration: val.target.value,
                            });
                          }}
                          placeholder="Enter Duration"
                        />
                      </Form.Group>

                      <br />
                      <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          pattern="^(?=.*[a-zA-Z]).{5,50}$"
                          as="textarea"
                          value={this.state.description}
                          onChange={(val) => {
                            this.setState({
                              description: val.target.value,
                            });
                          }}
                          style={{ height: "20vh" }}
                        />
                      </Form.Group>
                      {this.props.match.params.id === undefined ? (
                        <>
                          <Form.Group>
                            <Form.Label>Visa Id</Form.Label>
                            <Form.Control
                              placeholder="Enter Id"
                              minLength={6}
                              value={this.state.id}
                              required
                              type="text"
                              onChange={(value) => {
                                this.setState({
                                  id: value.target.value,
                                });
                              }}
                            />
                          </Form.Group>
                          <br />
                        </>
                      ) : (
                        <>
                          <br />
                          <h5>VISA ID : {this.state.id}</h5>
                          <br />
                        </>
                      )}
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Entry</Form.Label>
                        <Form.Select
                          size="sm"
                          value={this.state.entry}
                          aria-label="Default select example"
                          onChange={(val) => {
                            try {
                              this.setState({
                                entry: val.target.value,
                              });
                            } catch (e) {
                              alert("Wrong Value");
                            }
                          }}
                        >
                          <option value="Single Entry Visa">
                            Single Entry Visa
                          </option>
                          <option value="Multiple Entry Visa">
                            Multiple Entry Visa
                          </option>
                        </Form.Select>
                      </Form.Group>

                      <br />

                      <Form.Group>
                        <Form.Label>Note</Form.Label>
                        <Form.Control
                          type="text"
                          pattern="^(?=.*[a-zA-Z]).{5,50}$"
                          value={this.state.note}
                          onChange={(val) => {
                            this.setState({
                              note: val.target.value,
                            });
                          }}
                          placeholder="Enter Note"
                        />
                      </Form.Group>
                      <br />

                      <Form.Group>
                        <Form.Label>Price (MGA)</Form.Label>
                        <Form.Control
                          type="number"
                          pattern="^(([1-9]*)|(([1-9]*)\.([0-9]*)))$"
                          value={this.state.price}
                          onChange={(val) => {
                            this.setState({
                              price: val.target.value,
                            });
                          }}
                          placeholder="Enter Price "
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Container>
                <br />
                <Container>
                  <Row>
                    <Col></Col>
                    <Col>
                      <hr />
                      <br />
                      <Form.Group>
                        <Button
                          cypressid="submit"
                          variant="info"
                          type="submit"
                          size="large"
                          style={{ width: "100%" }}
                          //onClick={this.handleSubmit}
                        >
                          Submit
                        </Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </Container>
              </Form>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    firebaseauth: state.firebase.auth,
    visa: state.visa,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSingleVisa: (id) => dispatch(GetSingleVisa(id)),
    addVisa: (doc) => dispatch(AddVisa(doc)),
    updateVisa: (id, doc) => dispatch(UpdateVisa(id, doc)),
    getLastVisa: () => dispatch(GetLastVisa()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditVisa);
