import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import { Container, Button, Row, Form, Col, Modal } from "react-bootstrap";

import { Icon } from "rsuite";
import {
  GetHotelApplications,
  UpdateHotelApplicationStatus,
} from "../../store/actions/hotelApplicationActions";
import Loading from "../widgets/Loading";
import { connect } from "react-redux";

class HotelApplications extends Component {
  constructor(props) {
    document.title = "Hotel Applications";
    super(props);
    this.state = {
      hotelApplicationsData: {
        columns: [],
        rows: [],
      },
      deleteModal: false,
      showModal: false,
      selectedHotelApplication: null,
      sucessmessage: null,
      hotelDecision: "0",
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleInputClose = this.handleInputClose.bind(this);
  }

  handleDelete = (news) => {
    this.setState({
      selectedHotelApplication: news,
      deleteModal: true,
    });
  };

  handleConfirm = () => {
    this.props.deleteHotelApplication(this.state.selectedHotelApplication.id);
    this.setState({
      selectedHotelApplication: null,
      deleteModal: false,
      showModal: false,
    });
  };

  handleConfirmStatus = () => {
    this.props.updateHotelApplicationStatus(
      this.state.selectedHotelApplication.id,
      this.state.hotelDecision,
      this.state.selectedHotelApplication.changed
    );
    this.setState({
      selectedHotelApplication: null,
      deleteModal: false,
      showModal: false,
    });
  };
  handleInputClose = () => {
    this.setState({
      deleteModal: false,
      showModal: false,
    });
  };
  componentDidMount() {
    this.props.getHotelApplications();
  }
  componentDidUpdate(pr, cr) {
    if (
      pr.hotelapplication.loading === false &&
      this.props.hotelapplication.loading === false &&
      pr.hotelapplication.hotelApplications !==
        this.props.hotelapplication.hotelApplications
    ) {
      this.setState({
        hotelApplicationsData: {
          columns: [
            {
              label: "Date",
              field: "created_at",
            },
            {
              label: "Name",
              field: "name",
            },
            {
              label: "Email",
              field: "hemail",
            },
            {
              label: "Phone",
              field: "hphone",
            },
            {
              label: "Hotel",
              field: "hotel_title",
            },
            {
              label: "Status",
              field: "status",
              sort: "asc",
            },
          ],
          rows: this.props.hotelapplication.hotelApplications.map((row) => {
            return {
              ...row,
              name: <>{row.hfname + " " + row.hlname}</>,
              phone: <>{row.status}</>,
              created_at: <>{row.created_at.toDate().toLocaleDateString()}</>,
              status: (
                <>
                  <Form.Select
                    size="sm"
                    disabled={row.changed}
                    style={{
                      color:
                        row.status === 1
                          ? "green"
                          : row.status === 2
                          ? "red"
                          : "black",
                      width: "auto",
                      borderWidth: "3px",
                      borderColor:
                        row.status === 1
                          ? "green"
                          : row.status === 2
                          ? "red"
                          : "grey",
                    }}
                    value={row.status.toString()}
                    aria-label="Default select example"
                    onChange={(val) => {
                      try {
                        console.log(parseInt(val.target.value));
                        this.setState({
                          hotelDecision: parseInt(val.target.value),
                          selectedHotelApplication: row,
                          showModal: true,
                        });
                      } catch (e) {
                        alert("Wrong Value");
                      }
                    }}
                  >
                    <option>Open this select menu</option>
                    <option value="0">
                      <span
                        class="badge badge-warning"
                        style={{ backgroundColor: "yellow", color: "black" }}
                      >
                        Pending
                      </span>
                    </option>
                    <option value="1" style={{ color: "green" }}>
                      {" "}
                      <span
                        class="badge badge-success"
                        style={{ backgroundColor: "green" }}
                      >
                        Approved
                      </span>
                    </option>
                    <option value="2" style={{ color: "red" }}>
                      <span
                        class="badge badge-danger"
                        style={{ backgroundColor: "red" }}
                      >
                        Rejected
                      </span>
                    </option>
                  </Form.Select>
                  {/* {row.status === 0 ? (
                    <span
                      class="badge badge-warning"
                      style={{ backgroundColor: "yellow", color: "black" }}
                    >
                      Pending
                    </span>
                  ) : row.status === 1 ? (
                    <span
                      class="badge badge-success"
                      style={{ backgroundColor: "green" }}
                    >
                      Approved
                    </span>
                  ) : (
                    <span
                      class="badge badge-danger"
                      style={{ backgroundColor: "red" }}
                    >
                      Rejected
                    </span>
                  )} */}
                </>
              ),
            };
          }),
        },
      });
    } else if (
      pr.hotelapplication.loading === true &&
      this.props.hotelapplication.loading === false
    ) {
      this.setState({
        hotelApplicationsData: {
          columns: [
            {
              label: "Date",
              field: "created_at",
            },
            {
              label: "Name",
              field: "name",
            },
            {
              label: "Email",
              field: "hemail",
            },
            {
              label: "Phone",
              field: "hphone",
            },
            {
              label: "Hotel",
              field: "hotel_title",
            },
            {
              label: "Status",
              field: "status",
              sort: "asc",
            },
          ],
          rows: this.props.hotelapplication.hotelApplications.map((row) => {
            return {
              ...row,
              name: <>{row.hfname + " " + row.hlname}</>,

              created_at: <>{row.created_at.toDate().toLocaleDateString()}</>,
              status: (
                <>
                  <Form.Select
                    size="sm"
                    disabled={row.changed}
                    style={{
                      color:
                        row.status === 1
                          ? "green"
                          : row.status === 2
                          ? "red"
                          : "black",
                      width: "auto",
                      borderWidth: "3px",
                      borderColor:
                        row.status === 1
                          ? "green"
                          : row.status === 2
                          ? "red"
                          : "grey",
                    }}
                    value={row.status.toString()}
                    aria-label="Default select example"
                    onChange={(val) => {
                      try {
                        this.setState({
                          hotelDecision: parseInt(val.target.value),
                          selectedHotelApplication: row,
                          showModal: true,
                        });
                      } catch (e) {
                        alert("Wrong Value");
                      }
                    }}
                  >
                    <option>Open this select menu</option>
                    <option value="0">
                      <span
                        class="badge badge-warning"
                        style={{ backgroundColor: "yellow", color: "black" }}
                      >
                        Pending
                      </span>
                    </option>
                    <option value="1" style={{ color: "green" }}>
                      {" "}
                      <span
                        class="badge badge-success"
                        style={{ backgroundColor: "green" }}
                      >
                        Approved
                      </span>
                    </option>
                    <option value="2" style={{ color: "red" }}>
                      <span
                        class="badge badge-danger"
                        style={{ backgroundColor: "red" }}
                      >
                        Rejected
                      </span>
                    </option>
                  </Form.Select>
                  {/* {row.status === 0 ? (
                    <span
                      class="badge badge-warning"
                      style={{ backgroundColor: "yellow", color: "black" }}
                    >
                      Pending
                    </span>
                  ) : row.status === 1 ? (
                    <span
                      class="badge badge-success"
                      style={{ backgroundColor: "green" }}
                    >
                      Approved
                    </span>
                  ) : (
                    <span
                      class="badge badge-danger"
                      style={{ backgroundColor: "red" }}
                    >
                      Rejected
                    </span>
                  )} */}
                </>
              ),
            };
          }),
        },
      });
    }
  }

  render() {
    return (
      <div>
        <Container fluid>
          <Row>
            <h3>
              Hotel Booking Requests :{" "}
              {this.props.hotelapplication.hotelApplications !== undefined &&
              this.props.hotelapplication.hotelApplications !== null &&
              this.props.hotelapplication.hotelApplications.length !== 0
                ? this.props.hotelapplication.hotelApplications.length
                : ""}
            </h3>
            <Col lg="11"></Col>
            <Col>
              {/* <Button
                hotelApplicationsriant="primary"
                style={{ marginTop: "25px" }}
                onClick={() => {
                  window.open("/addhotelapplication", "_blank");
                }}
              >
                Add
              </Button> */}
            </Col>
          </Row>
          {this.props.hotelapplication.loading ? (
            <Loading />
          ) : (
            <MDBDataTable
              cypressid="table"
              striped
              bordered
              hover
              responsive
              data={this.state.hotelApplicationsData}
            />
          )}
          <Modal
            style={{ opacity: 1 }}
            show={this.state.deleteModal}
            onHide={this.handleInputClose}
            animation={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are You Sure to Delete?{" "}
              {this.state.selectedHotelApplication &&
                this.state.selectedHotelApplication.name_en}
            </Modal.Body>
            <Modal.Footer>
              <Button
                cypressid="cancel_delete"
                hotelApplicationsriant="secondary"
                onClick={this.handleInputClose}
              >
                Cancel
              </Button>
              <Button
                cypressid="confirm_delete"
                hotelApplicationsriant="contained"
                style={{ backgroundColor: "#B00020", color: "white" }}
                onClick={this.handleConfirm}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            style={{ opacity: 1 }}
            show={this.state.showModal}
            animation={false}
            onHide={this.handleInputClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are You Sure to Change Status of{" "}
              {this.state.selectedHotelApplication &&
                this.state.selectedHotelApplication.id}
              ?
            </Modal.Body>
            <Modal.Footer>
              <Button
                hotelApplicationsriant="secondary"
                onClick={this.handleInputClose}
              >
                Cancel
              </Button>
              <Button
                hotelApplicationsriant="contained"
                style={{ backgroundColor: "#B00020", color: "white" }}
                onClick={this.handleConfirmStatus}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    firebaseauth: state.firebase.auth,
    hotelapplication: state.hotelapplication,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHotelApplications: () => dispatch(GetHotelApplications()),
    updateHotelApplicationStatus: (id, decision, changed) =>
      dispatch(UpdateHotelApplicationStatus(id, decision, changed)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HotelApplications);
