import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import authReducer from "./authReducer";
import hotelApplicationReducer from "./hotelApplicationReducer";
import visaApplicationReducer from "./visaApplicationReducer";
import hotelReducer from "./hotelReducer";
import visaReducer from "./visaReducer";
import faqReducer from "./faqReducer";
import userReducer from "./userReducer";
import currencyReducer from "./currencyReducer";
import statReducer from "./statReducer";

export default combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  auth: authReducer,
  hotelapplication: hotelApplicationReducer,
  visaapplication: visaApplicationReducer,
  hotel: hotelReducer,
  visa: visaReducer,
  faq: faqReducer,
  user: userReducer,
  currency: currencyReducer,
  stat: statReducer,
});
