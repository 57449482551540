import { toast } from "react-toastify";

const initState = {
  etb: 0,
  eur: 0,
  usd: 0,
  updateRate: false,
  loading: false,
};

const currencyReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_RATES":
      return {
        ...state,
        etb: action.payload["ETB"],
        eur: action.payload["EUR"],
        usd: action.payload["USD"],
        updateRate: false,
        loading: false,
      };
    case "GET_RATES_ERROR":
      toast.error("Couldn't Load Rates");
      console.log(action.payload);
      return {
        ...state,
        loading: false,
      };

    case "UPDATE_RATES":
      toast.success("Successfully Updated");
      return {
        ...state,
        etb: action.payload.etb,
        eur: action.payload.eur,
        usd: action.payload.usd,
        addUpdateRate: true,
        loading: false,
      };
    case "UPDATE_RATES_ERROR":
      toast.error("Couldn't Add/Update Rate");
      console.log(action.payload);
      return {
        ...state,
        loading: false,
      };
    case "LOADING_RATES":
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default currencyReducer;
