import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import { Container, Button, Row, Form, Col, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  GetVisaApplications,
  UpdateVisaApplicationStatus,
  UpdateVisaApplicationPaymentStatus,
  ApproveVisaApplicationStatus,
} from "../../store/actions/visaApplicationActions";
import Loading from "../widgets/Loading";
import { connect } from "react-redux";
import { Icon } from "rsuite";

class VisaApplications extends Component {
  constructor (props) {
    document.title = "Visa Applications";
    super(props);
    this.state = {
      visaApplicationsData: {
        columns: [],
        rows: [],
      },
      deleteModal: false,
      showModal: false,
      showPaymentModal: false,
      selectedVisaApplication: null,
      visaDecision: "0",
      evisa: null,
      sucessmessage: null,
      paymenttype: 'PayPal',
      paymenttxn: '',
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleInputClose = this.handleInputClose.bind(this);
  }

  handleMore = (news) => {
    this.setState({
      selectedVisaApplication: news,
      deleteModal: true,
    });
  };

  handleDelete = (news) => {
    this.setState({
      selectedVisaApplication: news,
      deleteModal: true,
    });
  };

  handleConfirm = () => {
    this.props.deleteVisaApplication(this.state.selectedVisaApplication.id);
    this.setState({
      selectedVisaApplication: null,
      deleteModal: false,
      showModal: false,
    });
  };

  handleChange = (e) => {
    if (e.target.files[0]) {
      // console.log(e.target.files[0]);
      //10, 485, 760
      if (e.target.files[0].size < 10485760) {
        this.setState({
          evisa: e.target.files[0],
        });
      } else {
        this.setState({
          evisa: null,
        });
        toast.error("File has to be less than 10MB");
      }
    }
  };

  handleConfirmStatus = () => {
    if (this.state.visaDecision === 1) {
      if (this.state.evisa !== null) {
        this.props.approveVisaApplicationStatus(
          this.state.selectedVisaApplication.id,
          this.state.visaDecision,
          this.state.selectedVisaApplication.changed,
          this.state.evisa,
          this.state.selectedVisaApplication.phone,
          this.state.selectedVisaApplication.fname
        );
      } else {
        toast.error("Upload E-Visa To Confirm");
      }
    } else {
      this.props.updateVisaApplicationStatus(
        this.state.selectedVisaApplication.id,
        this.state.visaDecision,
        this.state.selectedVisaApplication.changed
      );
    }
    this.setState({
      selectedVisaApplication: null,
      deleteModal: false,
      showModal: false,
      showPaymentModal: false,
      evisa: null,
    });
  };

  handlePaymentChange = () => {
    this.props.updateVisaApplicationPaymentStatus(
      this.state.selectedVisaApplication.id,
      this.state.paymenttype,
      this.state.paymenttxn,
      this.state.selectedVisaApplication.changed
    );
    this.setState({
      selectedVisaApplication: null,
      deleteModal: false,
      showModal: false,
      showPaymentModal: false,
      paymenttype: 'PayPal',
      paymenttxn: '',
      evisa: null,
    });
  };


  handleInputClose = () => {
    this.setState({
      deleteModal: false,
      showModal: false,
      showPaymentModal: false,
      paymenttype: 'PayPal',
      paymenttxn: ''
    });
  };

  componentDidMount() {
    this.props.getVisaApplications();
  }
  componentDidUpdate(pr, cr) {
    if (
      pr.visaapplication.loading === false &&
      this.props.visaapplication.loading === false &&
      pr.visaapplication.visaApplications !==
      this.props.visaapplication.visaApplications
    ) {
      this.setState({
        visaApplicationsData: {
          columns: [
            {
              label: "Date",
              field: "created_at",
            },
            {
              label: "Name",
              field: "name",
            },
            {
              label: "Reference",
              field: "id",
            },
            {
              label: "Phone",
              field: "phone",
            },
            {
              label: "Visa",
              field: "visa_name",
            },
            {
              label: "Status",
              field: "status",
              sort: "asc",
            },
            {
              label: "Payment",
              field: "payment",
              sort: "asc"
            },
            {
              label: "Detail",
              field: "more",
            },
          ],
          rows: this.props.visaapplication.visaApplications.map((row) => {
            return {
              ...row,
              name: <>{row.fname + " " + row.lname}</>,
              created_at: <>{row.created_at.toDate().toLocaleDateString()}</>,
              payment: <>

                {row.paid ?
                  <span
                    class="badge badge-success"
                    style={{ backgroundColor: "green" }}
                  >
                    Paid
                  </span>
                  :
                  <>
                    <span
                      class="badge badge-danger"
                      style={{ backgroundColor: "red" }}
                    >
                      Not Paid
                    </span>
                    <Button
                      variant="contained"
                      style={{
                        color: "red",
                        fontSize: "0.2rem",
                      }}
                      onClick={() => {
                        try {
                          this.setState({
                            selectedVisaApplication: row,
                            showPaymentModal: true
                          });
                        } catch (e) {
                          alert("Wrong Value");
                        }
                      }}
                    >
                      <Icon icon="pencil" />
                    </Button>
                  </>
                }

              </>,

              status: (
                <>
                  <Form.Select
                    size="sm"
                    disabled={row.changed}
                    style={{
                      color:
                        row.status === 1
                          ? "green"
                          : row.status === 2
                            ? "red"
                            : "black",
                      width: "auto",
                      borderWidth: "3px",
                      borderColor:
                        row.status === 1
                          ? "green"
                          : row.status === 2
                            ? "red"
                            : "grey",
                    }}
                    value={row.status.toString()}
                    aria-label="Default select example"
                    onChange={(val) => {
                      try {
                        this.setState({
                          visaDecision: parseInt(val.target.value),
                          selectedVisaApplication: row,
                          showModal: true,
                          evisa: null,
                        });
                      } catch (e) {
                        alert("Wrong Value");
                      }
                    }}
                  >
                    <option>Open this select menu</option>
                    <option value="0">
                      <span
                        class="badge badge-warning"
                        style={{ backgroundColor: "yellow", color: "black" }}
                      >
                        Pending
                      </span>
                    </option>
                    <option value="1" style={{ color: "green" }}>
                      {" "}
                      <span
                        class="badge badge-success"
                        style={{ backgroundColor: "green" }}
                      >
                        Approved
                      </span>
                    </option>
                    <option value="2" style={{ color: "red" }}>
                      <span
                        class="badge badge-danger"
                        style={{ backgroundColor: "red" }}
                      >
                        Rejected
                      </span>
                    </option>
                  </Form.Select>
                  {/* {row.status === 0 ? (
                    <span
                      class="badge badge-warning"
                      style={{ backgroundColor: "yellow", color: "black" }}
                    >
                      Pending
                    </span>
                  ) : row.status === 1 ? (
                    <span
                      class="badge badge-success"
                      style={{ backgroundColor: "green" }}
                    >
                      Approved
                    </span>
                  ) : (
                    <span
                      class="badge badge-danger"
                      style={{ backgroundColor: "red" }}
                    >
                      Rejected
                    </span>
                  )} */}
                </>
              ),
              more: (
                <>
                  <Button
                    variant="contained"
                    style={{ color: "white", fontSize: "0.75rem" }}
                    className="btn btn-info"
                    onClick={() => {
                      this.handleMore(row);
                    }}
                  >
                    More
                  </Button>
                </>
              ),
            };
          }),
        },
      });
    } else if (
      pr.visaapplication.loading === true &&
      this.props.visaapplication.loading === false
    ) {
      this.setState({
        visaApplicationsData: {
          columns: [
            {
              label: "Date",
              field: "created_at",
            },
            {
              label: "Name",
              field: "name",
            },
            {
              label: "Reference",
              field: "id",
            },
            {
              label: "Phone",
              field: "phone",
            },
            {
              label: "Visa",
              field: "visa_name",
            },
            {
              label: "Status",
              field: "status",
              sort: "asc",
            },
            {
              label: "Payment",
              field: "payment",
              sort: "asc"
            },
            {
              label: "Detail",
              field: "more",
            },
          ],
          rows: this.props.visaapplication.visaApplications.map((row) => {
            return {
              ...row,
              name: <>{row.fname + " " + row.lname}</>,
              created_at: <>{row.created_at.toDate().toLocaleDateString()}</>,
              payment: <>

                {row.paid ?
                  <span
                    class="badge badge-success"
                    style={{ backgroundColor: "green" }}
                  >
                    Paid
                  </span>
                  :
                  <>
                    <span
                      class="badge badge-danger"
                      style={{ backgroundColor: "red" }}
                    >
                      Not Paid
                    </span>
                    <Button
                      variant="contained"
                      style={{
                        color: "red",
                        fontSize: "0.2rem",
                      }}
                      onClick={() => {
                        try {
                          this.setState({
                            selectedVisaApplication: row,
                            showPaymentModal: true
                          });
                        } catch (e) {
                          alert("Wrong Value");
                        }
                      }}
                    >
                      <Icon icon="pencil" />
                    </Button>
                  </>
                }

              </>,

              status: (
                <>
                  <Form.Select
                    size="sm"
                    disabled={row.changed}
                    style={{
                      color:
                        row.status === 1
                          ? "green"
                          : row.status === 2
                            ? "red"
                            : "black",
                      width: "auto",
                      borderWidth: "3px",
                      borderColor:
                        row.status === 1
                          ? "green"
                          : row.status === 2
                            ? "red"
                            : "grey",
                    }}
                    onChange={(val) => {
                      try {
                        this.setState({
                          visaDecision: parseInt(val.target.value),
                          selectedVisaApplication: row,
                          showModal: true,
                          evisa: null,
                        });
                      } catch (e) {
                        alert("Wrong Value");
                      }
                    }}
                    value={row.status.toString()}
                    aria-label="Default select example"
                  >
                    <option>Open this select menu</option>
                    <option value="0">
                      <span
                        class="badge badge-warning"
                        style={{ backgroundColor: "yellow", color: "black" }}
                      >
                        Pending
                      </span>
                    </option>
                    <option value="1" style={{ color: "green" }}>
                      {" "}
                      <span
                        class="badge badge-success"
                        style={{ backgroundColor: "green" }}
                      >
                        Approved
                      </span>
                    </option>
                    <option value="2" style={{ color: "red" }}>
                      <span
                        class="badge badge-danger"
                        style={{ backgroundColor: "red" }}
                      >
                        Rejected
                      </span>
                    </option>
                  </Form.Select>
                  {/* {row.status === 0 ? (
                <span
                  class="badge badge-warning"
                  style={{ backgroundColor: "yellow", color: "black" }}
                >
                  Pending
                </span>
              ) : row.status === 1 ? (
                <span
                  class="badge badge-success"
                  style={{ backgroundColor: "green" }}
                >
                  Approved
                </span>
              ) : (
                <span
                  class="badge badge-danger"
                  style={{ backgroundColor: "red" }}
                >
                  Rejected
                </span>
              )} */}
                </>
              ),
              more: (
                <>
                  <Button
                    variant="contained"
                    style={{ color: "white", fontSize: "0.75rem" }}
                    className="btn btn-info"
                    onClick={() => {
                      this.handleMore(row);
                    }}
                  >
                    More
                  </Button>
                </>
              ),
            };
          }),
        },
      });
    }
  }

  render() {
    return (
      <div>
        <Container fluid>
          <Row>
            <h3>
              Visa Applications :{" "}
              {this.props.visaapplication.visaApplications !== undefined &&
                this.props.visaapplication.visaApplications !== null &&
                this.props.visaapplication.visaApplications.length !== 0
                ? this.props.visaapplication.visaApplications.length
                : ""}
            </h3>
            <Col lg="11"></Col>
            <Col>
              {/* <Button
                visaApplicationsriant="primary"
                style={{ marginTop: "25px" }}
                onClick={() => {
                  window.open("/addvisaapplication", "_blank");
                }}
              >
                Add
              </Button> */}
            </Col>
          </Row>
          {this.props.visaapplication.loading ? (
            <Loading />
          ) : (
            <MDBDataTable
              cypressid="table"
              striped
              bordered
              hover
              responsive
              data={this.state.visaApplicationsData}
            />
          )}
          {/* <Modal
            style={{ opacity: 1 }}
            show={this.state.deleteModal}
            onHide={this.handleInputClose}
            animation={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are You Sure to Delete?{" "}
              {this.state.selectedVisaApplication &&
                this.state.selectedVisaApplication.name_en}
            </Modal.Body>
            <Modal.Footer>
              <Button
                cypressid="cancel_delete"
                visaApplicationsriant="secondary"
                onClick={this.handleInputClose}
              >
                Cancel
              </Button>
              <Button
                cypressid="confirm_delete"
                visaApplicationsriant="contained"
                style={{ backgroundColor: "#B00020", color: "white" }}
                onClick={this.handleConfirm}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal> */}
          <Modal
            style={{ opacity: 1 }}
            show={this.state.showModal}
            animation={false}
            onHide={this.handleInputClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are You Sure to Change Status of{" "}
              {this.state.selectedVisaApplication &&
                this.state.selectedVisaApplication.id}
              ?
              {this.state.selectedVisaApplication &&
                this.state.visaDecision === 1 ? (
                <Form.Group controlId="formFile">
                  <Form.Label>Insert E-Visa</Form.Label>
                  <Form.Control
                    onChange={this.handleChange}
                    type="file"
                    accept="application/pdf"
                  />
                </Form.Group>
              ) : (
                "Rejected"
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                visaApplicationsriant="secondary"
                onClick={this.handleInputClose}
              >
                Cancel
              </Button>
              <Button
                visaApplicationsriant="contained"
                style={{ backgroundColor: "#B00020", color: "white" }}
                disabled={
                  this.state.visaDecision === 1 && this.state.evisa === null
                }
                onClick={this.handleConfirmStatus}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            style={{ opacity: 1 }}
            show={this.state.deleteModal}
            onHide={this.handleInputClose}
            animation={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {this.state.selectedVisaApplication !== null
                  ? this.state.selectedVisaApplication.id
                  : ""}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5>
                {this.state.selectedVisaApplication !== null
                  ? this.state.selectedVisaApplication.fname +
                  " " +
                  this.state.selectedVisaApplication.lname
                  : ""}
              </h5>
              <br />
              <h6>
                {this.state.selectedVisaApplication !== null
                  ? "Visa Id : " + this.state.selectedVisaApplication.visa_id
                  : ""}
              </h6>
              <h6>
                {this.state.selectedVisaApplication !== null
                  ? "Visa Title : " +
                  this.state.selectedVisaApplication.visa_name
                  : ""}
              </h6>
              <h6>
                {this.state.selectedVisaApplication !== null
                  ? "Travel Date : " +
                  this.state.selectedVisaApplication.tdate
                    .toDate()
                    .toLocaleDateString()
                  : ""}
              </h6>

              <h6>
                {this.state.selectedVisaApplication !== null
                  ? "Purpose : " + this.state.selectedVisaApplication.purpo
                  : ""}
              </h6>
              <br />
              <h6>
                {this.state.selectedVisaApplication !== null
                  ? "Phone : " + this.state.selectedVisaApplication.phone
                  : ""}
              </h6>
              <h6>
                {this.state.selectedVisaApplication !== null
                  ? "Email : " + this.state.selectedVisaApplication.email
                  : ""}
              </h6>
              <h6>
                {this.state.selectedVisaApplication !== null
                  ? "Citizenship : " + this.state.selectedVisaApplication.from
                  : ""}
              </h6>
              <h6>
                {this.state.selectedVisaApplication !== null
                  ? "Profession : " + this.state.selectedVisaApplication.profes
                  : ""}
              </h6>
              <h6>
                {this.state.selectedVisaApplication !== null
                  ? "Passport No : " + this.state.selectedVisaApplication.passno
                  : ""}
              </h6>
              <h6>
                {this.state.selectedVisaApplication !== null
                  ? "Source : " + this.state.selectedVisaApplication.client
                  : ""}
              </h6>
              <br />
              <h6>
                {this.state.selectedVisaApplication !== null
                  ? this.state.selectedVisaApplication.paid ? "Paid : Yes" : "Paid : No"
                  : ""}
              </h6>
              {this.state.selectedVisaApplication !== null
                ? this.state.selectedVisaApplication.paid ?
                  this.state.selectedVisaApplication.paymenttype !== undefined ?
                    < h6 >
                      {"Type : " + this.state.selectedVisaApplication.paymenttype}
                    </h6>
                    : "" : "" : ""}
              {this.state.selectedVisaApplication !== null
                ? this.state.selectedVisaApplication.paid ?
                  this.state.selectedVisaApplication.paymenttxn !== undefined ?
                    < h6 >
                      {"Txn : " + this.state.selectedVisaApplication.paymenttxn}
                    </h6>
                    : "" : "" : ""}

              <br />
              <h6>
                {this.state.selectedVisaApplication !== null
                  ? "Created At : " +
                  this.state.selectedVisaApplication.created_at
                    .toDate()
                    .toLocaleDateString()
                  : ""}
              </h6>

              <br />
              <br />

              {this.state.selectedVisaApplication !== null ? (
                <img
                  style={{ width: "100%" }}
                  src={this.state.selectedVisaApplication.passportscan}
                  alt="Passport Scan"
                />
              ) : (
                ""
              )}

              <br />

              <br />
            </Modal.Body>
          </Modal>

          <Modal
            style={{ opacity: 1 }}
            show={this.state.showPaymentModal}
            animation={false}
            onHide={this.handleInputClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Payment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are You Sure to Change Payment Status of{" "}
              {this.state.selectedVisaApplication &&
                this.state.selectedVisaApplication.id}
              ?
              {this.state.selectedVisaApplication &&
                (
                  <div>
                    <br />
                    <Form.Group>
                      <Form.Label>Payment Type</Form.Label>
                      <Form.Select
                        value={this.state.paymenttype}
                        required
                        onChange={(val) => {
                          try {
                            this.setState({
                              paymenttype: val.target.value,
                            });
                          } catch (e) {
                            alert("Wrong Value");
                          }
                        }}
                      >

                        <option value={'PayPal'}>PayPal</option>
                        <option value={'Card'}>Card</option>
                        <option value={'Mvola'}>Mvola</option>
                        <option value={'Orange Money'}>Orange Money</option>
                        <option value={'Airtel'}>Airtel</option>
                        <option value={'Other'}>Other</option>

                      </Form.Select>
                    </Form.Group>
                    <br />
                    <Form.Group>
                      <Form.Label>Transaction ID</Form.Label>
                      <Form.Control
                        type="text"
                        minLength={5}
                        value={this.state.paymenttxn}
                        onChange={(val) => {
                          this.setState({
                            paymenttxn: val.target.value,
                          });
                        }}
                        placeholder="Enter Payment Txn Id"
                      />

                    </Form.Group>
                  </div>

                )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                visaApplicationsriant="secondary"
                onClick={this.handleInputClose}
              >
                Cancel
              </Button>
              <Button
                visaApplicationsriant="contained"
                style={{ backgroundColor: "#B00020", color: "white" }}
                disabled={
                  this.state.paymenttxn === '' || this.state.paymenttxn === null || this.state.paymenttxn === undefined ||
                  this.state.paymenttype === null || this.state.paymenttype === undefined
                }
                onClick={this.handlePaymentChange}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    firebaseauth: state.firebase.auth,
    visaapplication: state.visaapplication,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVisaApplications: () => dispatch(GetVisaApplications()),
    updateVisaApplicationStatus: (id, decision, changed) =>
      dispatch(UpdateVisaApplicationStatus(id, decision, changed)),
    approveVisaApplicationStatus: (id, decision, changed, pdf, phone, fname) =>
      dispatch(ApproveVisaApplicationStatus(id, decision, changed, pdf, phone, fname)),
    updateVisaApplicationPaymentStatus: (id, paymenttype, paymenttxn, changed) =>
      dispatch(UpdateVisaApplicationPaymentStatus(id, paymenttype, paymenttxn, changed)),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VisaApplications);
