import React, { Component } from "react";

import { Form, Button, Row, Container, Col } from "react-bootstrap";
import { SignIn, ResetPassword } from "../../store/actions/authActions";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Loading from "../widgets/Loading";
import emiralogo from "../../assets/images/emiralogo.jpg";

class Login extends Component {
  state = {
    email: "",
    forgetpasswordemail: false,
    password: "",
  };

  handleSubmit = (e) => {
    if (this.state.forgetpasswordemail) {
      this.props.resetPassword(this.state.email);
    } else {
      this.props.signIn({
        email: this.state.email,
        password: this.state.password,
      });
    }
    e.preventDefault();
  };

  render() {
    if (
      !this.props.firebaseauth.uid ||
      (this.props.profile.isEmpty && this.props.profile.isLoaded)
    )
      return (
        <div>
          {this.props.auth.loading ? (
            <Loading />
          ) : (
            <Container>
              <Row className="justify-content-md-center">
                <Col xs lg="4">
                  <Form id="loginform" onSubmit={this.handleSubmit}>
                    <div style={{ textAlign: "center", marginBottom: "2rem" }}>
                      <img
                        src={emiralogo}
                        alt="Emira"
                        style={{ height: "4rem", width: "auto" }}
                      />
                      <h5>
                        {this.state.forgetpasswordemail
                          ? "Enter your email to reset your password"
                          : "Welcome To Emira E-Visa"}
                      </h5>
                    </div>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label cypresstext="email">Email address</Form.Label>
                      <Form.Control
                        cypressid="loginemail"
                        value={this.state.email}
                        required
                        type="email"
                        onChange={(value) => {
                          this.setState({
                            email: value.target.value,
                          });
                        }}
                        placeholder="Enter email"
                      />
                    </Form.Group>
                    {!this.state.forgetpasswordemail && (
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          required
                          cypressid="loginpassword"
                          value={this.state.password}
                          type="password"
                          onChange={(value) => {
                            this.setState({
                              password: value.target.value,
                            });
                          }}
                          placeholder="Password"
                        />
                      </Form.Group>
                    )}
                    <Form.Group className="mb-3">
                      <Button
                        cypressid="loginbutton"
                        className="fullwidthbutton"
                        variant="primary"
                        type="submit"
                      >
                        {this.state.forgetpasswordemail ? "Reset" : "Submit"}
                      </Button>
                    </Form.Group>
                    <span
                      className="forgotpassword"
                      onClick={() => {
                        this.setState({
                          forgetpasswordemail: true,
                        });
                      }}
                      style={{ float: "right" }}
                    >
                      Forgot/Reset Password?
                    </span>
                  </Form>
                </Col>
              </Row>
            </Container>
          )}
        </div>
      );
    return <Redirect to="/" />;
  }
}

const mapStateToProps = (state) => {
  return {
    firebaseauth: state.firebase.auth,
    auth: state.auth,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(SignIn(creds)),
    resetPassword: (email) => dispatch(ResetPassword(email)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
