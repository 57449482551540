import { storage } from "../../config/firebaseConfig";

export const SignIn = (creds) => {
  return (dispatch, getState, { getFirebase }) => {
    dispatch(StartLoadingAuth());
    const firebase = getFirebase();
    firebase
      .auth()
      .signInWithEmailAndPassword(creds.email, creds.password)
      .then(() => {
        dispatch({ type: "SIGN_IN" });
      })
      .catch((err) => {
        dispatch({ type: "SIGN_IN_ERROR", payload: err });
      });
  };
};

export const SaveProfile = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "SAVE_PROFILE", payload: getState().firebase.profile });
  };
};

export const SignOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    dispatch(StartLoadingAuth());
    const firebase = getFirebase();
    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: "SIGN_OUT" });
      })
      .catch((err) => {
        dispatch({ type: "SIGN_OUT_ERROR", payload: err });
      });
  };
};

export const ResetPassword = (email) => {
  return (dispatch, getState, { getFirebase }) => {
    dispatch(StartLoadingAuth());
    const firebase = getFirebase();
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        console.log("sent");
        dispatch({ type: "RESET_PASSWORD" });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: "RESET_PASSWORD_ERROR", payload: err });
      });
  };
};

export const UpdateProfileWithImage = (fname, lname, image) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to the database
    try {
      dispatch(StartLoadingAuth());
      const uploadTask = storage.ref("profiles/" + image.name).put(image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          dispatch({
            type: "UPDATE_PROFILE_ERROR",
            payload: error,
          });
        },
        () => {
          storage
            .ref("/profiles")
            .child(image.name)
            .getDownloadURL()
            .then((url) => {
              const firestore = getFirestore();
              firestore
                .collection("admin")
                .doc(getState().firebase.auth.uid)
                .update({
                  fname: fname,
                  lname: lname,
                  image: url,
                })
                .then(() => {
                  dispatch({ type: "UPDATE_PROFILE" });
                })
                .catch((e) => {
                  dispatch({ type: "UPDATE_PROFILE_ERROR", payload: e });
                });
            });
        }
      );
    } catch (err) {
      dispatch({ type: "UPDATE_PROFILE_ERROR", payload: err });
    }
  };
};

export const UpdateProfile = (fname, lname) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to the database
    try {
      dispatch(StartLoadingAuth());
      const firestore = getFirestore();
      firestore
        .collection("admin")
        .doc(getState().firebase.auth.uid)
        .update({
          fname: fname,
          lname: lname,
        })
        .then(() => {
          dispatch({ type: "UPDATE_PROFILE" });
        })
        .catch((e) => {
          dispatch({ type: "UPDATE_PROFILE_ERROR", payload: e });
        });
    } catch (err) {
      dispatch({ type: "UPDATE_PROFILE_ERROR", payload: err });
    }
  };
};

export const StartLoadingAuth = () => {
  return {
    type: "LOADING_AUTH",
  };
};
