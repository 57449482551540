import { toast } from "react-toastify";

const initState = {
  visaApplications: [],
  loading: false,
};

const visaApplicationReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_VISA_APPLICATIONS":
      return {
        ...state,
        visaApplications: action.payload,
        loading: false,
      };
    case "GET_VISA_APPLICATIONS_ERROR":
      toast.error("Couldn't Load Applications");
      console.log(action.payload);
      return {
        ...state,
        loading: false,
      };
    case "UPDATE_VISA_APP_STATUS":
      return {
        ...state,
        visaApplications: state.visaApplications.filter((n) => {
          if (n.id === action.payload.id) {
            n.status = action.payload.status;
          }
          return n.id !== 123;
        }),
        visa: null,
        loading: false,
      };
    case "UPDATE_VISA_APP_STATUS_ERROR":
      toast.error("Couldn't Update Status");
      console.log(action.payload);
      return {
        ...state,
        visa: null,
        loading: false,
      };
    case "UPDATE_VISA_PAYMENT_STATUS":
      return {
        ...state,
        visaApplications: state.visaApplications.filter((n) => {
          if (n.id === action.payload.id) {
            n['paymenttxn'] = action.payload.paymenttxn;
            n['paymenttype'] = action.payload.paymenttype;
            n.paid = true;
          }
          return n.id !== 123;
        }),
        visa: null,
        loading: false,
      };
    case "UPDATE_VISA_PAYMENT_STATUS_ERROR":
      toast.error("Couldn't Update Status");
      console.log(action.payload);
      return {
        ...state,
        visa: null,
        loading: false,
      };
    case "APPROVE_VISA_APP_STATUS":
      return {
        ...state,
        visaApplications: state.visaApplications.filter((n) => {
          if (n.id === action.payload.id) {
            n.status = action.payload.status;
          }
          return n.id !== 123;
        }),
        visa: null,
        loading: false,
      };
    case "APPROVE_VISA_APP_STATUS_ERROR":
      toast.error("Couldn't Update Status");
      console.log(action.payload);
      return {
        ...state,
        visa: null,
        loading: false,
      };

    case "LOADING_VISA_APPLICATION":
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default visaApplicationReducer;
