import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/auth";
import "firebase/storage";
// For Firebase JS SDK v7.2

const firebaseConfig = {
  apiKey: "AIzaSyDhYgZakbCr0doEOevXcNwXg7lAb_WlY7M",
  authDomain: "emiraevisa-278d8.firebaseapp.com",
  projectId: "emiraevisa-278d8",
  storageBucket: "emiraevisa-278d8.appspot.com",
  messagingSenderId: "1076854637047",
  appId: "1:1076854637047:web:4f5e642b8a20ca2cf6eef2",
  measurementId: "G-RXXQS8S4LD",
};

// Initialize Firebase
export const secondaryApp = firebase.initializeApp(firebaseConfig, "Secondary");
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.firestore().settings({ timestampsInSnaphots: true });

export const storage = firebase.storage();
export default firebase;
