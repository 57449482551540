import { toast } from "react-toastify";

const initState = {
  user: null,
  users: [],
  pickUser: "",
  loading: false,
  userimage: "",
  addUpdateUser: false,
};

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_USERS":
      return {
        ...state,
        users: action.payload,
        userimage: "",
        addUpdateUser: false,
        user: null,
        loading: false,
      };
    case "GET_USERS_ERROR":
      toast.error("Couldn't Load Users");
      console.log(action.payload);
      return {
        ...state,
        addUpdateUser: false,
        loading: false,
      };
    case "ADD_UPDATE_USER":
      window.alert("Successfully Submitted");
      return {
        ...state,
        userimage: "",
        addUpdateUser: true,
        user: null,
        loading: false,
      };
    case "ADD_UPDATE_USER_ERROR":
      toast.error("Couldn't Add/Update User");
      console.log(action.payload);
      return {
        ...state,
        userimage: "",
        user: null,
        loading: false,
      };
    case "DELETE_USER":
      toast.info("Successfully Deleted");
      return {
        ...state,
        users: state.users.filter((n) => {
          return n.id !== action.payload;
        }),
        user: null,
        userimage: "",
        loading: false,
      };
    case "DELETE_USER_ERROR":
      toast.error("Couldn't Delete User");
      console.log(action.payload);
      return {
        ...state,
        user: null,
        userimage: "",
        loading: false,
      };
    case "UPDATE_USER_STATUS":
      toast.success("Updated User Status");
      return {
        ...state,
        users: state.users.filter((n) => {
          if (n.id === action.payload) {
            n.status = !n.status;
          }
          return n.id !== 123;
        }),
        user: null,
        userimage: "",
        loading: false,
      };
    case "UPDATE_USER_STATUS_ERROR":
      toast.error("Couldn't Update Status");
      console.log(action.payload);
      return {
        ...state,
        user: null,
        userimage: "",
        loading: false,
      };
    case "GET_SINGLE_USER":
      return {
        ...state,
        user: action.payload,
        addUpdateUser: false,
        loading: false,
      };
    case "GET_SINGLE_USER_ERROR":
      toast.error("Couldn't Load User");
      console.log(action.payload);
      return {
        ...state,
        loading: false,
        addUpdateUser: false,
      };
    case "UPLOAD_USER_PICTURE":
      return {
        ...state,
        userimage: action.payload,
        addUpdateUser: false,
      };
    case "UPLOAD_USER_PICTURE_ERROR":
      toast.error("Couldn't Upload Picture");
      console.log(action.payload);
      return {
        ...state,
        loading: false,
        addUpdateUser: false,
      };
    case "LOADING_USER":
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default userReducer;
