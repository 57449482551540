export const GetHotelApplications = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to the database
    try {
      dispatch(StartLoadingHotelApp());
      const firestore = getFirestore();
      if (getState().auth.profile.type === 1) {
        firestore
          .collection("hotelapplications")
          .orderBy("created_at", "desc")
          .get()
          .then((application) => {
            const applicationlist = [];
            application.forEach((element) => {
              applicationlist.push({
                ...element.data(),
                id: element.id,
              });
            });
            dispatch({
              type: "GET_HOTEL_APPLICATIONS",
              payload: applicationlist,
            });
          })
          .catch((e) => {
            dispatch({ type: "GET_HOTEL_APPLICATIONS_ERROR", payload: e });
          });
      } else {
        firestore
          .collection("hotelapplications")
          .where("from", "==", getState().auth.profile.country)
          .orderBy("created_at", "desc")
          .get()
          .then((application) => {
            const applicationlist = [];
            application.forEach((element) => {
              applicationlist.push({
                ...element.data(),
                id: element.id,
              });
            });
            dispatch({
              type: "GET_HOTEL_APPLICATIONS",
              payload: applicationlist,
            });
          })
          .catch((e) => {
            dispatch({ type: "GET_HOTEL_APPLICATIONS_ERROR", payload: e });
          });
      }
    } catch (err) {
      dispatch({ type: "GET_HOTEL_APPLICATIONS_ERROR", payload: err });
    }
  };
};

export const UpdateHotelApplicationStatus = (id, decision, changed) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      if (!changed) {
        dispatch(StartLoadingHotelApp());
        // make async call to the database
        const firestore = getFirestore();
        firestore
          .collection("hotelapplications")
          .doc(id)
          .update({
            status: decision,
            updated_at: new Date(),
          })
          .then(() => {
            firestore
              .collection("stats")
              .doc("hotel")
              .update({
                [decision === 1 ? "approved" : "rejected"]:
                  firestore.FieldValue.increment(1),
              })
              .then(() => {
                dispatch({
                  type: "UPDATE_HOTEL_APP_STATUS",
                  payload: { id: id, status: decision },
                });
              })
              .catch((e) => {
                dispatch({ type: "UPDATE_HOTEL_APP_STATUS_ERROR", payload: e });
              });
          })
          .catch((e) => {
            dispatch({ type: "UPDATE_HOTEL_APP_STATUS_ERROR", payload: e });
          });
      } else {
        dispatch({
          type: "UPDATE_HOTEL_APP_STATUS_ERROR",
          payload: "Not Allowed",
        });
      }
    } catch (err) {
      dispatch({ type: "UPDATE_HOTEL_APP_STATUS_ERROR", payload: err });
    }
  };
};

export const StartLoadingHotelApp = () => {
  return {
    type: "LOADING_HOTEL_APPLICATION",
  };
};
