import React, { Component } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Pie, Doughnut } from "react-chartjs-2";

import Loading from "../widgets/Loading";
import { connect } from "react-redux";
import { GetStats } from "../../store/actions/statActions";
import { toast } from "react-toastify";
import { getRandomColor } from "../../functions/util";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Tooltip,
  Legend,
  Title,
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);
// import { Chart, ArcElement, Legend } from "chart.js";
// Chart.register(ArcElement);
// Chart.register(Legend);

class Dashboard extends Component {
  state = {
    stats: [],
    visa: [],
    hotel: [],
    vcountrydata: { labels: [], datasets: [] },
    hcountrydata: { labels: [], datasets: [] },
    visastatusdata: { labels: [], datasets: [] },
    visatypedata: { labels: [], datasets: [] },
    hotelstatusdata: { labels: [], datasets: [] },
    hoteltypedata: { labels: [], datasets: [] },
  };

  componentDidMount() {
    this.props.getStats();
  }

  componentDidUpdate(pr, cr) {
    if (pr.stat.loading === true && this.props.stat.loading === false) {
      try {
        // console.log(
        //   Object.keys(this.props.stat.stats[0]).map((c) => c.toUpperCase())
        // );
        // console.log({
        //   data: Object.values(this.props.stat.stats[0]),
        //   backgroundColor: getRandomColor(
        //     Object.keys(this.props.stat.stats[0]).length
        //   ),
        //   borderWidth: 1,
        // });
        this.setState({
          vcountrydata: {
            labels: Object.keys(this.props.stat.stats[0]).map((c) =>
              c.toUpperCase()
            ),
            datasets: [
              {
                data: Object.values(this.props.stat.stats[0]).map((c) =>
                  parseInt(c.toString())
                ),
                backgroundColor: getRandomColor(
                  Object.keys(this.props.stat.stats[0]).length
                ),
                borderWidth: 1,
              },
            ],
          },
          hcountrydata: {
            labels: Object.keys(this.props.stat.stats[1]).map((c) =>
              c.toUpperCase()
            ),
            datasets: [
              {
                data: Object.values(this.props.stat.stats[1]).map((c) =>
                  parseInt(c.toString())
                ),
                backgroundColor: getRandomColor(
                  Object.keys(this.props.stat.stats[1]).length
                ),
                borderWidth: 1,
              },
            ],
          },
          visastatusdata: {
            labels: ["Approved", "Rejected", "Pending"],
            datasets: [
              {
                data: [
                  this.props.stat.stats[3]["approved"],
                  this.props.stat.stats[3]["rejected"],
                  this.props.stat.stats[3]["total"] -
                    (this.props.stat.stats[3]["rejected"] +
                      this.props.stat.stats[3]["approved"]),
                ],
                backgroundColor: [
                  "rgba(82, 196, 26 , 0.7)",
                  "rgba(245, 34, 45 , 0.7)",
                  "rgba(250, 173, 20, 0.7)",
                ],
                borderWidth: 1,
              },
            ],
          },
          hotelstatusdata: {
            labels: ["Approved", "Rejected", "Pending"],
            datasets: [
              {
                data: [
                  this.props.stat.stats[2]["approved"],
                  this.props.stat.stats[2]["rejected"],
                  this.props.stat.stats[2]["total"] -
                    (this.props.stat.stats[2]["rejected"] +
                      this.props.stat.stats[2]["approved"]),
                ],
                backgroundColor: [
                  "rgba(82, 196, 26 , 0.7)",
                  "rgba(245, 34, 45 , 0.7)",
                  "rgba(250, 173, 20, 0.7)",
                ],
                borderWidth: 1,
              },
            ],
          },
          visatypedata: {
            labels: this.props.stat.visa.map((c) => c.title),
            datasets: [
              {
                data: this.props.stat.visa.map((c) => c.applications),
                backgroundColor: getRandomColor(
                  Object.keys(this.props.stat.visa).length
                ),
                borderWidth: 1,
              },
            ],
          },
          hoteltypedata: {
            labels: this.props.stat.hotel.map((c) => c.title),
            datasets: [
              {
                data: this.props.stat.hotel.map((c) => c.applications),
                backgroundColor: getRandomColor(
                  Object.keys(this.props.stat.hotel).length
                ),
                borderWidth: 1,
              },
            ],
          },
        });
      } catch (e) {
        toast.error("Couldn't Get Data");
      }
      this.setState({
        stats: this.props.stat.stats,
        visa: this.props.stat.visa,
        hotel: this.props.stat.hotel,
      });
      console.log(this.state.vcountrydata);
    }
  }

  render() {
    return this.props.stat.loading ? (
      <Loading />
    ) : (
      <div>
        <Container fluid>
          <Row>
            <h3>Dashboard</h3>
            {/* <Col lg="11">
              <h3>Dashboard</h3>
            </Col>
            <Col>
              <Button
                variant="primary"
                style={{ marginTop: "25px" }}
                onClick={() => {
                  window.open("/addhotel", "_blank");
                }}
              >
                Add
              </Button>
            </Col> */}
          </Row>
          <Row>
            <Col sm={12}>
              <Container fluid>
                <Row>
                  <Col sm={4}>
                    <Card body>
                      <Pie
                        data={{
                          labels: this.state.vcountrydata.labels,
                          datasets: this.state.vcountrydata.datasets,
                        }}
                        width={null}
                        height={250}
                        options={{
                          plugins: {
                            legend: {
                              display: true,
                              position: "bottom",
                            },
                            title: {
                              text: "Visa Applications Per Country",
                              display: true,
                            },
                          },
                          responsive: true,
                          maintainAspectRatio: false,
                        }}
                      />
                    </Card>
                  </Col>

                  <Col sm={4}>
                    <Card body>
                      <Doughnut
                        data={{
                          labels: this.state.visastatusdata.labels,
                          datasets: this.state.visastatusdata.datasets,
                        }}
                        width={null}
                        height={250}
                        options={{
                          plugins: {
                            legend: {
                              display: true,
                              position: "bottom",
                            },
                            title: {
                              text: "Visa Applications Status",
                              display: true,
                            },
                          },
                          responsive: true,
                          maintainAspectRatio: false,
                        }}
                      />
                    </Card>
                  </Col>
                  <Col sm={4}>
                    <Card body>
                      <Pie
                        data={{
                          labels: this.state.visatypedata.labels,
                          datasets: this.state.visatypedata.datasets,
                        }}
                        width={null}
                        height={250}
                        options={{
                          plugins: {
                            legend: {
                              display: true,
                              position: "bottom",
                            },
                            title: {
                              text: "Visa Type Applications",
                              display: true,
                            },
                          },
                          responsive: true,
                          maintainAspectRatio: false,
                        }}
                      />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4}>
                    <Card body>
                      <Pie
                        data={{
                          labels: this.state.hcountrydata.labels,
                          datasets: this.state.hcountrydata.datasets,
                        }}
                        width={null}
                        height={250}
                        options={{
                          plugins: {
                            legend: {
                              display: true,
                              position: "bottom",
                            },
                            title: {
                              text: "Hotel Bookings Per Country",
                              display: true,
                            },
                          },
                          responsive: true,
                          maintainAspectRatio: false,
                        }}
                      />
                    </Card>
                  </Col>
                  <Col sm={4}>
                    <Card body>
                      <Doughnut
                        data={{
                          labels: this.state.hotelstatusdata.labels,
                          datasets: this.state.hotelstatusdata.datasets,
                        }}
                        width={null}
                        height={250}
                        options={{
                          plugins: {
                            legend: {
                              display: true,
                              position: "bottom",
                            },
                            title: {
                              text: "Hotel Applications Status",
                              display: true,
                            },
                          },
                          responsive: true,
                          maintainAspectRatio: false,
                        }}
                      />
                    </Card>
                  </Col>
                  <Col sm={4}>
                    <Card body>
                      <Pie
                        data={{
                          labels: this.state.hoteltypedata.labels,
                          datasets: this.state.hoteltypedata.datasets,
                        }}
                        width={null}
                        height={250}
                        options={{
                          plugins: {
                            legend: {
                              display: true,
                              position: "bottom",
                            },
                            title: {
                              text: "Hotel Type Applications",
                              display: true,
                            },
                          },
                          responsive: true,
                          maintainAspectRatio: false,
                        }}
                      />
                    </Card>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    firebaseauth: state.firebase.auth,
    stat: state.stat,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStats: () => dispatch(GetStats()),
    // // deleteHotel: (id) => dispatch(DeleteHotel(id)),
    // updateHotelStatus: (id, decision) =>
    //   dispatch(UpdateHotelStatus(id, decision)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
