export const GetFaqs = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to the database
    try {
      dispatch(StartLoadingFaq());
      const firestore = getFirestore();

      firestore
        .collection("faqs")
        .orderBy("created_at", "asc")
        .get()
        .then((faq) => {
          const faqlist = [];
          faq.forEach((element) => {
            faqlist.push({
              ...element.data(),
              id: element.id,
            });
          });
          dispatch({ type: "GET_FAQS", payload: faqlist });
        })
        .catch((e) => {
          dispatch({ type: "GET_FAQS_ERROR", payload: e });
        });
    } catch (err) {
      dispatch({ type: "GET_FAQS_ERROR", payload: err });
    }
  };
};

export const DeleteFaq = (id) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      dispatch(StartLoadingFaq());
      // make async call to the database
      const firestore = getFirestore();
      firestore
        .collection("faqs")
        .doc(id)
        .delete()
        .then(() => {
          dispatch({
            type: "DELETE_FAQ",
            payload: id,
          });
        })
        .catch((e) => {
          dispatch({ type: "DELETE_FAQ_ERROR", payload: e });
        });
    } catch (err) {
      dispatch({ type: "DELETE_FAQ_ERROR", payload: err });
    }
  };
};

export const AddFaq = (faq) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to the database
    try {
      dispatch(StartLoadingFaq());
      const firestore = getFirestore();

      firestore
        .collection("faqs")
        .add({
          created_at: new Date(),
          answer: faq.answer,
          question: faq.question,
        })
        .then(() => {
          dispatch({ type: "ADD_UPDATE_FAQ" });
        })
        .catch((err) => {
          dispatch({ type: "ADD_UPDATE_FAQ_ERROR", payload: err });
          console.log("Transaction failure:", err);
        });
    } catch (err) {
      dispatch({ type: "ADD_UPDATE_FAQ_ERROR", payload: err });
    }
  };
};

export const UpdateFaq = (id, faq) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to the database
    try {
      dispatch(StartLoadingFaq());
      const firestore = getFirestore();
      firestore
        .collection("faqs")
        .doc(id)
        .update({
          answer: faq.answer,
          question: faq.question,
        })
        .then(() => {
          dispatch({ type: "ADD_UPDATE_FAQ", payload: faq });
        })
        .catch((err) => {
          dispatch({ type: "ADD_UPDATE_FAQ_ERROR", payload: err });
          console.log("Transaction failure:", err);
        });
    } catch (err) {
      dispatch({ type: "ADD_UPDATE_FAQ_ERROR", payload: err });
    }
  };
};

export const GetSingleFaq = (id) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to the database
    try {
      dispatch(StartLoadingFaq());
      const firestore = getFirestore();
      firestore
        .collection("faqs")
        .doc(id)
        .get()
        .then((doc) => {
          dispatch({
            type: "GET_SINGLE_FAQ",
            payload: { ...doc.data(), id: id },
          });
        })
        .catch((e) => {
          dispatch({ type: "GET_SINGLE_FAQ_ERROR", payload: e });
        });
    } catch (err) {
      dispatch({ type: "GET_SINGLE_FAQ_ERROR", payload: err });
    }
  };
};

export const StartLoadingFaq = () => {
  return {
    type: "LOADING_FAQ",
  };
};
