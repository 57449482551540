import { storage } from "../../config/firebaseConfig";

export const GetVisaApplications = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to the database
    try {
      dispatch(StartLoadingVisaApp());
      const firestore = getFirestore();
      if (getState().auth.profile.type === 1) {
        firestore
          .collection("visaapplications")
          .orderBy("created_at", "desc")
          .get()
          .then((application) => {
            const applicationlist = [];
            application.forEach((element) => {
              applicationlist.push({
                ...element.data(),
                id: element.id,
              });
            });
            dispatch({
              type: "GET_VISA_APPLICATIONS",
              payload: applicationlist,
            });
          })
          .catch((e) => {
            dispatch({ type: "GET_VISA_APPLICATIONS_ERROR", payload: e });
          });
      } else {
        firestore
          .collection("visaapplications")
          .where("from", "==", getState().auth.profile.country)
          .orderBy("created_at", "desc")
          .get()
          .then((application) => {
            const applicationlist = [];
            application.forEach((element) => {
              applicationlist.push({
                ...element.data(),
                id: element.id,
              });
            });
            dispatch({
              type: "GET_VISA_APPLICATIONS",
              payload: applicationlist,
            });
          })
          .catch((e) => {
            dispatch({ type: "GET_VISA_APPLICATIONS_ERROR", payload: e });
          });
      }
    } catch (err) {
      dispatch({ type: "GET_VISA_APPLICATIONS_ERROR", payload: err });
    }
  };
};

export const ApproveVisaApplicationStatus = (id, decision, changed, pdf, phone, fname) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to the database
    try {
      if (!changed) {
        dispatch(StartLoadingVisaApp());
        const uploadTask = storage.ref("evisas/" + pdf.name).put(pdf);
        uploadTask.on(
          "state_changed",
          (snapshot) => { },
          (error) => {
            dispatch({
              type: "APPROVE_VISA_APP_STATUS_ERROR",
              payload: error,
            });
          },
          () => {
            storage
              .ref("/evisas")
              .child(pdf.name)
              .getDownloadURL()
              .then((url) => {
                const firestore = getFirestore();
                firestore
                  .collection("visaapplications")
                  .doc(id)
                  .update({
                    status: decision,
                    evisa: url,
                    updated_at: new Date(),
                  })
                  .then(() => {
                    firestore
                      .collection("stats")
                      .doc("visa")
                      .update({
                        approved: firestore.FieldValue.increment(1),
                      })
                      .then(() => {
                        fetch("https://serene-ocean-60681.herokuapp.com/text/success", {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify({
                            id: id,
                            phone: phone,
                            fname: fname
                          }),
                        })
                        dispatch({
                          type: "APPROVE_VISA_APP_STATUS",
                          payload: { id: id, status: decision },
                        });
                      })
                      .catch((e) => {
                        dispatch({
                          type: "APPROVE_VISA_APP_STATUS_ERROR",
                          payload: e,
                        });
                      });
                  })
                  .catch((e) => {
                    dispatch({
                      type: "APPROVE_VISA_APP_STATUS_ERROR",
                      payload: e,
                    });
                  });
              });
          }
        );
      } else {
        dispatch({
          type: "APPROVE_VISA_APP_STATUS_ERROR",
          payload: "Not Allowed",
        });
      }
    } catch (err) {
      dispatch({
        type: "APPROVE_VISA_APP_STATUS_ERROR",
        payload: err,
      });
    }
  };
};

export const UpdateVisaApplicationStatus = (id, decision, changed) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      if (!changed) {
        dispatch(StartLoadingVisaApp());
        // make async call to the database
        const firestore = getFirestore();
        firestore
          .collection("visaapplications")
          .doc(id)
          .update({
            status: decision,
            updated_at: new Date(),
          })
          .then(() => {
            firestore
              .collection("stats")
              .doc("hotel")
              .update({
                rejected: firestore.FieldValue.increment(1),
              })
              .then(() => {
                dispatch({
                  type: "UPDATE_VISA_APP_STATUS",
                  payload: { id: id, status: decision },
                });
              })
              .catch((e) => {
                dispatch({ type: "UPDATE_VISA_APP_STATUS_ERROR", payload: e });
              });
          })
          .catch((e) => {
            dispatch({ type: "UPDATE_VISA_APP_STATUS_ERROR", payload: e });
          });
      } else {
        dispatch({
          type: "UPDATE_VISA_APP_STATUS_ERROR",
          payload: "Not Allowed",
        });
      }
    } catch (err) {
      dispatch({ type: "UPDATE_VISA_APP_STATUS_ERROR", payload: err });
    }
  };
};

export const UpdateVisaApplicationPaymentStatus = (id, paymenttype, paymenttxn, changed) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      if (!changed) {
        dispatch(StartLoadingVisaApp());
        // make async call to the database
        const firestore = getFirestore();
        firestore
          .collection("visaapplications")
          .doc(id)
          .update({
            paymenttxn: paymenttxn,
            paymenttype: paymenttype,
            updated_at: new Date(),
          })
          .then(() => {
            dispatch({
              type: "UPDATE_VISA_PAYMENT_STATUS",
              payload: { id: id, paymenttype: paymenttype, paymenttxn: paymenttxn },
            });
          })
          .catch((e) => {
            dispatch({ type: "UPDATE_VISA_APP_PAYMENT_ERROR", payload: e });
          });
      } else {
        dispatch({
          type: "UPDATE_VISA_APP_PAYMENT_ERROR",
          payload: "Not Allowed",
        });
      }
    } catch (err) {
      dispatch({ type: "UPDATE_VISA_APP_PAYMENT_ERROR", payload: err });
    }
  };
};


export const StartLoadingVisaApp = () => {
  return {
    type: "LOADING_VISA_APPLICATION",
  };
};
