import React, { Component } from "react";

import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";

import Loading from "../widgets/Loading";
import { GetRates, UpdateRate } from "../../store/actions/currencyActions";
import { toast } from "react-toastify";

class EditCurrencies extends Component {
  state = {
    etb: 0,
    eur: 0,
    usd: 0,
    submitted: true,
  };

  componentDidMount() {
    document.title = "Currency Rates";
    this.props.getRates();
  }

  componentDidUpdate(p, c) {
    if (
      p.currency.loading === true &&
      this.props.currency.loading === false &&
      this.props.currency.updateRate === false &&
      this.props.currency.etb !== 0
    ) {
      this.setState({
        etb: this.props.currency.etb,
        eur: this.props.currency.eur,
        usd: this.props.currency.usd,
      });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (
      !isNaN(this.state.etb) &&
      !isNaN(this.state.eur) &&
      !isNaN(this.state.usd)
    ) {
      const currency = {
        etb: this.state.etb,
        eur: this.state.eur,
        usd: this.state.usd,
      };

      this.props.updateRate(currency);
    } else {
      toast.error("Please Enter Appropriate Values");
    }
  };
  render() {
    return (
      <Container fluid>
        <Row>
          <h3>
            <h3>Currencies</h3>
          </h3>
        </Row>
        <Row>
          <Col lg="12">
            {this.props.currency.loading === true ? (
              <Loading />
            ) : (
              <Form className="mt-3 " onSubmit={this.handleSubmit}>
                <Container>
                  <Row>
                    <Col lg={3}>
                      <Form.Group>
                        <Form.Label>1 MGA to ETB</Form.Label>
                        <Form.Control
                          type="number"
                          min={0}
                          step={0.00001}
                          value={this.state.etb}
                          onChange={(val) => {
                            this.setState({
                              etb: val.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>1 MGA to EUR</Form.Label>
                        <Form.Control
                          type="number"
                          min={0}
                          step={0.00001}
                          value={this.state.eur}
                          onChange={(val) => {
                            this.setState({
                              eur: val.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>1 MGA to USD</Form.Label>
                        <Form.Control
                          type="number"
                          min={0}
                          step={0.00001}
                          value={this.state.usd}
                          onChange={(val) => {
                            this.setState({
                              usd: val.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Container>
                <Container>
                  <Row>
                    <Col lg={3}>
                      <hr />
                      <Form.Group>
                        <Button
                          cypressid="submit"
                          variant="info"
                          type="submit"
                          size="large"
                          style={{ width: "100%" }}
                          //onClick={this.handleSubmit}
                        >
                          Submit
                        </Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </Container>
              </Form>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    firebaseauth: state.firebase.auth,
    currency: state.currency,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRates: () => dispatch(GetRates()),
    updateRate: (doc) => dispatch(UpdateRate(doc)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCurrencies);
