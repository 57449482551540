import React, { Component } from "react";
import { Avatar } from "rsuite";
import { Form, Button, Row, Container, Col } from "react-bootstrap";
import {
  UpdateProfile,
  UpdateProfileWithImage,
} from "../../store/actions/authActions";
import { connect } from "react-redux";
import Loading from "../widgets/Loading";
import { toast } from "react-toastify";

class Profile extends Component {
  state = {
    fname: "",
    lname: "",
    imageurl: "",
    image: null,
  };

  componentDidMount() {
    if (this.props.profile.isLoaded) {
      this.setState({
        fname: this.props.profile.fname,
        lname: this.props.profile.lname,
        imageurl: this.props.profile.image,
      });
    }
  }

  handleSubmit = (e) => {
    if (this.state.image === null) {
      this.props.updateProfile(this.state.fname, this.state.lname);
    } else {
      this.props.updateProfileWithImage(
        this.state.fname,
        this.state.lname,
        this.state.image
      );
    }
    e.preventDefault();
  };

  handleChange = (e) => {
    if (e.target.files[0]) {
      // console.log(e.target.files[0]);
      //10, 485, 760
      if (e.target.files[0].size < 5242880) {
        this.setState({
          image: e.target.files[0],
        });
      } else {
        this.setState({
          image: null,
        });
        toast.error("File has to be less than 5MB");
      }
    }
  };

  render() {
    return (
      <div>
        {!this.props.profile.isLoaded || this.props.auth.loading ? (
          <Loading />
        ) : (
          <Container>
            <Row className="justify-content-md-center">
              <Col xs lg="8">
                <Form id="profileform" onSubmit={this.handleSubmit}>
                  <div style={{ textAlign: "center", marginBottom: "2rem" }}>
                    <img
                      src={this.state.imageurl}
                      alt="Emira"
                      style={{
                        height: "5rem",
                        width: "5rem",
                        borderRadius: "50%",
                      }}
                    ></img>
                    {/* <Avatar
                      circle
                      src={this.state.imageurl}
                      alt="@admin"
                      style={{
                        height: "5rem",
                        width: "5rem",
                        // marginRight: "20px",
                        // position: "absolute",
                        // left: "20px",
                        // top: "15px",
                        lineHeight: "1.25",
                      }}
                    /> */}
                    <br />
                  </div>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label cypresstext="email">First Name</Form.Label>
                    <Form.Control
                      value={this.state.fname}
                      required
                      type="text"
                      onChange={(value) => {
                        this.setState({
                          fname: value.target.value,
                        });
                      }}
                      placeholder="Enter First Name"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label cypresstext="email">Last Name</Form.Label>
                    <Form.Control
                      cypressid="loginemail"
                      value={this.state.lname}
                      required
                      type="text"
                      onChange={(value) => {
                        this.setState({
                          lname: value.target.value,
                        });
                      }}
                      placeholder="Enter Last Name"
                    />
                  </Form.Group>

                  <Form.Group controlId="formFile">
                    <Form.Label>Profile Picture</Form.Label>
                    <Form.Control
                      onChange={this.handleChange}
                      type="file"
                      accept="image/*"
                    />
                  </Form.Group>
                  <br />
                  <Form.Group className="mb-3">
                    <Button
                      cypressid="loginbutton"
                      className="fullwidthbutton"
                      variant="primary"
                      type="submit"
                    >
                      {this.state.forgetpasswordemail ? "Reset" : "Submit"}
                    </Button>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    firebaseauth: state.firebase.auth,
    auth: state.auth,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateProfile: (fname, lname) => dispatch(UpdateProfile(fname, lname)),
    updateProfileWithImage: (fname, lname, image) =>
      dispatch(UpdateProfileWithImage(fname, lname, image)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
