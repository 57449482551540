import { toast } from "react-toastify";
import { storage } from "../../config/firebaseConfig";

export const GetHotels = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to the database
    try {
      dispatch(StartLoadingHotel());
      const firestore = getFirestore();

      firestore
        .collection("hoteltypes")
        .orderBy("title", "asc")
        .get()
        .then((data) => {
          const datalist = [];
          data.forEach((element) => {
            datalist.push({
              ...element.data(),
              id: element.id,
            });
          });
          dispatch({
            type: "GET_HOTELS",
            payload: datalist,
          });
        })
        .catch((e) => {
          dispatch({ type: "GET_HOTELS_ERROR", payload: e });
        });
    } catch (err) {
      dispatch({ type: "GET_HOTELS_ERROR", payload: err });
    }
  };
};

export const UpdateHotelStatus = (id, decision) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      dispatch(StartLoadingHotel());
      // make async call to the database
      const firestore = getFirestore();
      firestore
        .collection("hoteltypes")
        .doc(id)
        .update({
          status: decision,
        })
        .then(() => {
          dispatch({ type: "UPDATE_HOTEL_STATUS", payload: id });
        })
        .catch((e) => {
          dispatch({ type: "UPDATE_HOTEL_STATUS_ERROR", payload: e });
        });
    } catch (err) {
      dispatch({ type: "UPDATE_HOTEL_STATUS_ERROR", payload: err });
    }
  };
};

export const GetSingleHotel = (id) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to the database
    try {
      dispatch(StartLoadingHotel());
      const firestore = getFirestore();
      firestore
        .collection("hoteltypes")
        .doc(id)
        .get()
        .then((doc) => {
          dispatch({
            type: "GET_SINGLE_HOTEL",
            payload: { ...doc.data(), id: id },
          });
        })
        .catch((e) => {
          dispatch({ type: "GET_SINGLE_HOTEL_ERROR", payload: e });
        });
    } catch (err) {
      dispatch({ type: "GET_SINGLE_HOTEL_ERROR", payload: err });
    }
  };
};

export const AddHotel = (hotel) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to the database
    try {
      dispatch(StartLoadingHotel());
      const firestore = getFirestore();
      firestore
        .collection("hoteltypes")
        .doc(hotel.id)
        .get()
        .then((doc) => {
          if (doc.exists) {
            toast.error("A Hotel Already Exisits by " + hotel.id);
            dispatch({
              type: "ADD_UPDATE_HOTEL_ERROR",
              payload: "A Hotel Already Exisits by " + hotel.id,
            });
          } else {
            firestore
              .collection("hoteltypes")
              .doc(hotel.id)
              .set({
                updated_at: new Date(),
                created_at: new Date(),
                applications: 0,
                description: hotel.description,
                title: hotel.title,
                image: hotel.image,
                price: hotel.price,
                star: parseInt(hotel.star),
                status: true,
              })
              .then(() => {
                dispatch({ type: "ADD_UPDATE_HOTEL" });
              })
              .catch((err) => {
                dispatch({ type: "ADD_UPDATE_HOTEL_ERROR", payload: err });
                console.log("Transaction failure:", err);
              });
          }
        })
        .catch((e) => {
          dispatch({ type: "ADD_UPDATE_HOTEL_ERROR", payload: e });
        });
    } catch (err) {
      dispatch({ type: "ADD_UPDATE_HOTEL_ERROR", payload: err });
    }
  };
};

export const UpdateHotel = (id, hotel) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to the database
    try {
      dispatch(StartLoadingHotel());
      const firestore = getFirestore();
      firestore
        .collection("hoteltypes")
        .doc(id)
        .update({
          updated_at: new Date(),
          description: hotel.description,
          title: hotel.title,
          image: hotel.image,
          price: hotel.price,
          star: parseInt(hotel.star),
        })
        .then(() => {
          dispatch({ type: "ADD_UPDATE_HOTEL", payload: hotel });
        })
        .catch((err) => {
          dispatch({ type: "ADD_UPDATE_HOTEL_ERROR", payload: err });
          console.log("Transaction failure:", err);
        });
    } catch (err) {
      dispatch({ type: "ADD_UPDATE_HOTEL_ERROR", payload: err });
    }
  };
};

export const UploadHotelPicture = (picture) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to the database
    try {
      dispatch(StartLoadingHotel());
      const uploadTask = storage.ref("hotels/" + picture.name).put(picture);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          dispatch({
            type: "UPLOAD_HOTEL_PICTURE_ERROR",
            payload: error,
          });
        },
        () => {
          storage
            .ref("/hotels")
            .child(picture.name)
            .getDownloadURL()
            .then((url) => {
              dispatch({
                type: "UPLOAD_HOTEL_PICTURE",
                payload: url,
              });
            });
        }
      );
    } catch (err) {
      dispatch({
        type: "UPLOAD_HOTEL_PICTURE_ERROR",
        payload: err,
      });
    }
  };
};

export const StartLoadingHotel = () => {
  return {
    type: "LOADING_HOTEL",
  };
};
