import React, { Component } from "react";
import { Sidenav } from "rsuite";
import { Nav, Icon, Avatar } from "rsuite";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "rsuite/dist/styles/rsuite-default.css"; // or 'rsuite/dist/styles/rsuite-default.css'
// import 'rsuite/styles/less/index.less'

import { connect } from "react-redux";
import { SignOut } from "../../store/actions/authActions";

import VisaApplications from "../application/VisaApplications";
import HotelApplications from "../application/HotelApplications";
import Profile from "../auth/Profile";

class OfficeWrapper extends Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1",
      imageurl: "../../assets/images/emiralogo.jpg",
      fname: "Emira",
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleToggle() {
    this.setState({
      expanded: !this.state.expanded,
    });
  }
  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey,
    });
  }

  componentDidMount() {
    if (this.props.profile.isLoaded) {
      this.setState({
        imageurl: this.props.profile.image,
        fname: this.props.profile.fname,
      });
    }
  }

  render() {
    return (
      <div id="wrapperdiv">
        <div id="sidenav">
          <Sidenav>
            <Sidenav.Body>
              <Nav>
                {/* <Nav.Item
                  href="/dashboards"
                  eventKey="0"
                  icon={<Icon icon="dashboard" />}
                >
                  Dashboard
                </Nav.Item> */}

                <Nav.Item
                  cypressid="control"
                  href="/visaapplications"
                  eventKey="5"
                  icon={<Icon icon="list-ol" />}
                >
                  Visa Applications
                </Nav.Item>
                <Nav.Item
                  cypressid="control"
                  href="/hotelapplications"
                  eventKey="6"
                  icon={<Icon icon="address-book" />}
                >
                  Hotel Bookings
                </Nav.Item>
                {/* <Nav.Item
                  cypressid="visas"
                  href="/visas"
                  eventKey="4"
                  icon={<Icon icon="plane" />}
                >
                  Visas
                </Nav.Item>
                <Nav.Item
                  href="/hotels"
                  eventKey="3"
                  icon={<Icon icon="bed" />}
                >
                  Hotels
                </Nav.Item>
                <Nav.Item
                  href="/faqs"
                  eventKey="2"
                  icon={<Icon icon="question-circle" />}
                >
                  FAQs
                </Nav.Item>
                <Nav.Item
                  href="/currencies"
                  eventKey="8"
                  icon={<Icon icon="money" />}
                >
                  Currencies
                </Nav.Item>
                <Nav.Item
                  href="/users"
                  eventKey="7"
                  icon={<Icon icon="user-circle" />}
                >
                  Users
                </Nav.Item> */}
                <hr />
                <Nav.Item
                  cypressid="control"
                  href="/profile"
                  eventKey="5"
                  icon={
                    <img
                      src={this.state.imageurl}
                      alt="Emira"
                      style={{
                        height: "25px",
                        width: "25px",
                        borderRadius: "50%",
                        marginRight: "20px",
                        position: "absolute",
                        left: "20px",
                        top: "15px",
                        lineHeight: "1.25",
                      }}
                    />
                  }
                >
                  {this.state.fname}
                </Nav.Item>

                <Nav.Item
                  cypressid="logout"
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.signOut();
                  }}
                  eventKey="6"
                  icon={<Icon icon="sign-out" />}
                >
                  Log Out
                </Nav.Item>
              </Nav>
            </Sidenav.Body>
          </Sidenav>
        </div>

        <div id="wrapperspace">
          <div id="wrapperdiv">
            <div>
              <BrowserRouter>
                <Switch>
                  {/* <Route path="/hotels" component={Hotels} />
                  <Route path="/edithotel/:id" component={AddEditHotel} />
                  <Route path="/addhotel" component={AddEditHotel} /> */}
                  {/* <Route path="/addeditdoctor/:id" component={AddEditDoctor} /> */}
                  {/* <Route path="/adddoctor" component={AddEditDoctor} /> */}
                  {/* <Route path="/visas" component={Visas} />
                  <Route path="/editvisa/:id" component={AddEditVisa} />
                  <Route path="/addvisa" component={AddEditVisa} /> */}
                  <Route
                    path="/visaapplications"
                    component={VisaApplications}
                  />
                  <Route
                    path="/hotelapplications"
                    component={HotelApplications}
                  />
                  {/* <Route path="/faqs" component={Faqs} />
                  <Route path="/editfaq/:id" component={AddEditFaq} />
                  <Route path="/addfaq" component={AddEditFaq} />

                  <Route path="/users" component={Users} />
                  <Route path="/edituser/:id" component={AddEditUser} />
                  <Route path="/adduser" component={AddEditUser} />

                  <Route path="/currencies" component={Currency} /> */}

                  <Route path="/profile" component={Profile} />
                  {/* <Route path="/dashboard" component={Dashboard} /> */}
                  <Route path="/" exact={true} component={VisaApplications} />

                  {/* <Route
                    path="/addeditservice/:id"
                    component={AddEditService}
                  />
                  <Route path="/addservice" component={AddEditService} />

                  <Route path="/articles" component={Articles} />
                  <Route
                    path="/addeditarticle/:id"
                    component={AddEditArticle}
                  />
                  <Route path="/addarticle" component={AddEditArticle} />

                  <Route path="/receptionists" component={Receptionists} />
                  <Route
                    path="/addeditreception/:id"
                    component={AddEditReceptionist}
                  />
                  <Route path="/addreception" component={AddEditReceptionist} /> */}
                  {/* <Route path="/dashboard" component={Dashboard} /> */}
                </Switch>
              </BrowserRouter>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    firebaseauth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(SignOut()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OfficeWrapper);
