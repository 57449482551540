import axios from "axios";

export const GetRates = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to the database
    try {
      dispatch(StartLoadingRate());
      const firestore = getFirestore();
      firestore
        .collection("rate")
        .doc("1")
        .get()
        .then((doc) => {
          dispatch({
            type: "GET_RATES",
            payload: { ...doc.data().obj },
          });
        })
        .catch((e) => {
          dispatch({ type: "GET_RATES_ERROR", payload: e });
        });
    } catch (err) {
      dispatch({ type: "GET_RATES_ERROR", payload: err });
    }
  };
};

export const UpdateRate = (rates) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const client = axios.create({
      baseURL: "http://localhost:3000",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });

    client
      .post("pay", {
        id: "uae00011",
      })
      .then((response) => {
        console.log(response);
      });
    // make async call to the database
    // try {
    //   dispatch(StartLoadingRate());
    //   const firestore = getFirestore();
    //   firestore
    //     .collection("rate")
    //     .doc("1")
    //     .update({
    //       obj: {
    //         ETB: parseFloat(rates.etb),
    //         EUR: parseFloat(rates.eur),
    //         USD: parseFloat(rates.usd),
    //       },
    //       updated_at: new Date(),
    //     })
    //     .then(() => {
    //       dispatch({
    //         type: "UPDATE_RATES",
    //         payload: {
    //           ETB: rates.etb,
    //           EUR: rates.eur,
    //           USD: rates.usd,
    //           MGA: 1,
    //         },
    //       });
    //     })
    //     .catch((err) => {
    //       dispatch({ type: "UPDATE_RATES_ERROR", payload: err });
    //       console.log("Transaction failure:", err);
    //     });
    // } catch (err) {
    //   dispatch({ type: "UPDATE_RATES_ERROR", payload: err });
    // }
  };
};

export const StartLoadingRate = () => {
  return {
    type: "LOADING_RATES",
  };
};
