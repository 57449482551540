import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import { Container, Button, Row, Form, Col, Modal } from "react-bootstrap";

import { Icon } from "rsuite";
import {
  GetHotels,
  // DeleteHotel,
  UpdateHotelStatus,
} from "../../store/actions/hotelActions";
import Loading from "../widgets/Loading";
import { connect } from "react-redux";

class Hotels extends Component {
  constructor(props) {
    document.title = "Hotels";
    super(props);
    this.state = {
      hoteltypesData: {
        columns: [],
        rows: [],
      },
      deleteModal: false,
      showModal: false,
      selectedHotel: null,
      sucessmessage: null,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleInputClose = this.handleInputClose.bind(this);
  }

  handleDelete = (news) => {
    this.setState({
      selectedHotel: news,
      deleteModal: true,
    });
  };

  handleConfirm = () => {
    this.props.deleteHotel(this.state.selectedHotel.id);
    this.setState({
      selectedHotel: null,
      deleteModal: false,
      showModal: false,
    });
  };

  handleConfirmStatus = () => {
    this.props.updateHotelStatus(
      this.state.selectedHotel.id,
      !this.state.selectedHotel.status
    );
    this.setState({
      selectedHotel: null,
      deleteModal: false,
      showModal: false,
    });
  };
  handleInputClose = () => {
    this.setState({
      deleteModal: false,
      showModal: false,
    });
  };
  componentDidMount() {
    this.props.getHotels();
  }
  componentDidUpdate(pr, cr) {
    if (
      pr.hotel.loading === false &&
      this.props.hotel.loading === false &&
      pr.hotel.hoteltypes !== this.props.hotel.hoteltypes
    ) {
      this.setState({
        hoteltypesData: {
          columns: [
            {
              label: "Picture",
              field: "image",
            },
            {
              label: "Title",
              field: "title",
              sort: "asc",
            },
            {
              label: "Price",
              field: "price",
              sort: "asc",
            },
            {
              label: "Bookings",
              field: "applications",
              sort: "asc",
            },
            {
              label: "Status",
              field: "status",
              sort: "asc",
              width: 50,
            },
            {
              label: "Edit",
              field: "edit",
              sort: "asc",
              width: 50,
            },

            // {
            //   label: "Delete",
            //   field: "delete",
            //   sort: "asc",
            //   width: 50,
            // },
          ],
          rows: this.props.hotel.hoteltypes.map((row) => {
            return {
              ...row,
              image: <img src={row.image} width="50" alt={row.title} />,
              status: (
                <>
                  <Form.Switch
                    type="switch"
                    id="active"
                    style={{ marginLeft: "2em" }}
                    checked={row.status}
                    onChange={() => {
                      this.setState({
                        selectedHotel: row,
                        showModal: true,
                      });
                    }}
                  />
                </>
              ),
              edit: (
                <>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#e0ac1d",
                      color: "black",
                      fontSize: "0.25rem",
                    }}
                    onClick={() => {
                      window.open("/edithotel/" + row.id, "_blank");
                    }}
                  >
                    <Icon icon="pencil" />
                  </Button>
                </>
              ),
              // delete: (
              //   <>
              //     <Button
              //       id="delete"
              //       variant="contained"
              //       style={{ backgroundColor: "#B00020", color: "white" }}
              //       onClick={() => {
              //         this.handleDelete(row);
              //       }}
              //     >
              //       Delete
              //     </Button>
              //   </>
              // ),
            };
          }),
        },
      });
    } else if (
      pr.hotel.loading === true &&
      this.props.hotel.loading === false
    ) {
      this.setState({
        hoteltypesData: {
          columns: [
            {
              label: "Picture",
              field: "image",
            },
            {
              label: "Title",
              field: "title",
              sort: "asc",
            },
            {
              label: "Price",
              field: "price",
              sort: "asc",
            },
            {
              label: "Bookings",
              field: "applications",
              sort: "asc",
            },
            {
              label: "Status",
              field: "status",
              sort: "asc",
              width: 50,
            },
            {
              label: "Edit",
              field: "edit",
              sort: "asc",
              width: 50,
            },

            // {
            //   label: "Delete",
            //   field: "delete",
            //   sort: "asc",
            //   width: 50,
            // },
          ],
          rows: this.props.hotel.hoteltypes.map((row) => {
            return {
              ...row,
              image: <img src={row.image} width="50" alt={row.title} />,
              status: (
                <>
                  <Form.Switch
                    type="switch"
                    id="active"
                    style={{ marginLeft: "2em" }}
                    checked={row.status}
                    onChange={() => {
                      this.setState({
                        selectedHotel: row,
                        showModal: true,
                      });
                    }}
                  />
                </>
              ),
              edit: (
                <>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#e0ac1d",
                      color: "black",
                      fontSize: "0.25rem",
                    }}
                    onClick={() => {
                      window.open("/edithotel/" + row.id, "_blank");
                    }}
                  >
                    <Icon icon="pencil" />
                  </Button>
                </>
              ),
              // delete: (
              //   <>
              //     <Button
              //       id="delete"
              //       variant="contained"
              //       style={{ backgroundColor: "#B00020", color: "white" }}
              //       onClick={() => {
              //         this.handleDelete(row);
              //       }}
              //     >
              //       Delete
              //     </Button>
              //   </>
              // ),
            };
          }),
        },
      });
    }
  }

  render() {
    return (
      <div>
        <Container fluid>
          <Row>
            <Col lg="11">
              <h3>
                Active Hotels :{" "}
                {this.props.hotel.hoteltypes !== undefined &&
                this.props.hotel.hoteltypes !== null &&
                this.props.hotel.hoteltypes.length !== 0
                  ? this.props.hotel.hoteltypes.filter((f) => f.status).length
                  : ""}
              </h3>
            </Col>
            <Col>
              <Button
                variant="primary"
                style={{ marginTop: "25px" }}
                onClick={() => {
                  window.open("/addhotel", "_blank");
                }}
              >
                Add
              </Button>
            </Col>
          </Row>
          {this.props.hotel.loading ? (
            <Loading />
          ) : (
            <MDBDataTable
              cypressid="table"
              striped
              bordered
              hover
              responsive
              data={this.state.hoteltypesData}
            />
          )}
          <Modal
            style={{ opacity: 1 }}
            show={this.state.deleteModal}
            onHide={this.handleInputClose}
            animation={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are You Sure to Delete?{" "}
              {this.state.selectedHotel && this.state.selectedHotel.name_en}
            </Modal.Body>
            <Modal.Footer>
              <Button
                cypressid="cancel_delete"
                variant="secondary"
                onClick={this.handleInputClose}
              >
                Cancel
              </Button>
              <Button
                cypressid="confirm_delete"
                variant="contained"
                style={{ backgroundColor: "#B00020", color: "white" }}
                onClick={this.handleConfirm}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            style={{ opacity: 1 }}
            show={this.state.showModal}
            animation={false}
            onHide={this.handleInputClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are You Sure to Change Status of{" "}
              {this.state.selectedHotel && this.state.selectedHotel.title}?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleInputClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: "#B00020", color: "white" }}
                onClick={this.handleConfirmStatus}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    firebaseauth: state.firebase.auth,
    hotel: state.hotel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHotels: () => dispatch(GetHotels()),
    // deleteHotel: (id) => dispatch(DeleteHotel(id)),
    updateHotelStatus: (id, decision) =>
      dispatch(UpdateHotelStatus(id, decision)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Hotels);
