import { toast } from "react-toastify";

const initState = {
  hotel: null,
  hoteltypes: [],
  hotelimage: "",
  addUpdateHotel: false,
  loading: false,
};

const hotelReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_HOTELS":
      return {
        ...state,
        hotel: null,
        addUpdateHotel: false,
        hoteltypes: action.payload,
        hotelimage: "",
        loading: false,
      };
    case "GET_HOTELS_ERROR":
      toast.error("Couldn't Load Hotels");
      console.log(action.payload);
      return {
        ...state,
        loading: false,
      };
    case "GET_SINGLE_HOTEL":
      return {
        ...state,
        hotel: action.payload,
        addUpdateHotel: false,
        hotelimage: "",
        loading: false,
      };
    case "GET_SINGLE_HOTEL_ERROR":
      toast.error("Couldn't Load Hotel");
      console.log(action.payload);
      return {
        ...state,
        loading: false,
        hotelimage: "",
        addUpdateHotel: false,
      };
    case "UPDATE_HOTEL_STATUS":
      return {
        ...state,
        hoteltypes: state.hoteltypes.filter((n) => {
          if (n.id === action.payload) {
            n.status = !n.status;
          }
          return n.id !== 123;
        }),
        hotel: null,
        hotelimage: "",
        addUpdateHotel: false,
        loading: false,
      };
    case "UPDATE_HOTEL_STATUS_ERROR":
      toast.error("Couldn't Update Status");
      console.log(action.payload);
      return {
        ...state,
        hotel: null,
        hotelimage: "",
        addUpdateHotel: false,
        loading: false,
      };

    case "ADD_UPDATE_HOTEL":
      window.alert("Successfully Submitted");
      return {
        ...state,
        hotel: null,
        hotelimage: "",
        addUpdateHotel: true,
        loading: false,
      };
    case "ADD_UPDATE_HOTEL_ERROR":
      toast.error("Couldn't Add/Update Hotel");
      console.log(action.payload);
      return {
        ...state,
        loading: false,
      };
    case "UPLOAD_HOTEL_PICTURE":
      return {
        ...state,
        hotelimage: action.payload,
        addUpdateHotel: false,
      };
    case "UPLOAD_HOTEL_PICTURE_ERROR":
      toast.error("Couldn't Upload Picture");
      console.log(action.payload);
      return {
        ...state,
        loading: false,
        addUpdateHotel: false,
      };
    case "LOADING_HOTEL":
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default hotelReducer;
