import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import { Container, Button, Row, Form, Col, Modal } from "react-bootstrap";

import { Icon } from "rsuite";
import {
  GetVisas,
  // DeleteVisa,
  UpdateVisaStatus,
} from "../../store/actions/visaActions";
import Loading from "../widgets/Loading";
import { connect } from "react-redux";

class Visas extends Component {
  constructor(props) {
    document.title = "Visas";
    super(props);
    this.state = {
      visatypesData: {
        columns: [],
        rows: [],
      },
      deleteModal: false,
      showModal: false,
      selectedVisa: null,
      sucessmessage: null,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleInputClose = this.handleInputClose.bind(this);
  }

  handleDelete = (news) => {
    this.setState({
      selectedVisa: news,
      deleteModal: true,
    });
  };

  handleConfirm = () => {
    this.props.deleteVisa(this.state.selectedVisa.id);
    this.setState({
      selectedVisa: null,
      deleteModal: false,
      showModal: false,
    });
  };

  handleConfirmStatus = () => {
    this.props.updateVisaStatus(
      this.state.selectedVisa.id,
      !this.state.selectedVisa.status
    );
    this.setState({
      selectedVisa: null,
      deleteModal: false,
      showModal: false,
    });
  };
  handleInputClose = () => {
    this.setState({
      deleteModal: false,
      showModal: false,
    });
  };
  componentDidMount() {
    this.props.getVisas();
  }
  componentDidUpdate(pr, cr) {
    if (
      pr.visa.loading === false &&
      this.props.visa.loading === false &&
      pr.visa.visatypes !== this.props.visa.visatypes
    ) {
      this.setState({
        visatypesData: {
          columns: [
            {
              label: "Duration",
              field: "duration",
              sort: "asc",
            },
            {
              label: "Entry",
              field: "entry",
              sort: "asc",
            },
            {
              label: "Price",
              field: "price",
              sort: "asc",
            },
            {
              label: "ID",
              field: "id",
              sort: "asc",
            },
            {
              label: "Bookings",
              field: "applications",
              sort: "asc",
            },
            {
              label: "Status",
              field: "status",
              sort: "asc",
              width: 50,
            },
            {
              label: "Edit",
              field: "edit",
              sort: "asc",
              width: 50,
            },

            // {
            //   label: "Delete",
            //   field: "delete",
            //   sort: "asc",
            //   width: 50,
            // },
          ],
          rows: this.props.visa.visatypes.map((row) => {
            return {
              ...row,
              price: (
                <>
                  {" "}
                  {row.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
                    " MGA"}
                </>
              ),
              status: (
                <>
                  <Form.Switch
                    type="switch"
                    id="active"
                    style={{ marginLeft: "2em" }}
                    checked={row.status}
                    onChange={() => {
                      this.setState({
                        selectedVisa: row,
                        showModal: true,
                      });
                    }}
                  />
                </>
              ),
              edit: (
                <>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#e0ac1d",
                      color: "black",
                      fontSize: "0.25rem",
                    }}
                    onClick={() => {
                      window.open("/editvisa/" + row.id, "_blank");
                    }}
                  >
                    <Icon icon="pencil" />
                  </Button>
                </>
              ),
              // delete: (
              //   <>
              //     <Button
              //       id="delete"
              //       variant="contained"
              //       style={{ backgroundColor: "#B00020", color: "white" }}
              //       onClick={() => {
              //         this.handleDelete(row);
              //       }}
              //     >
              //       Delete
              //     </Button>
              //   </>
              // ),
            };
          }),
        },
      });
    } else if (pr.visa.loading === true && this.props.visa.loading === false) {
      this.setState({
        visatypesData: {
          columns: [
            {
              label: "Duration",
              field: "duration",
              sort: "asc",
            },
            {
              label: "Entry",
              field: "entry",
              sort: "asc",
            },
            {
              label: "Price",
              field: "price",
              sort: "asc",
            },
            {
              label: "ID",
              field: "id",
              sort: "asc",
            },
            {
              label: "Bookings",
              field: "applications",
              sort: "asc",
            },
            {
              label: "Status",
              field: "status",
              sort: "asc",
              width: 50,
            },
            {
              label: "Edit",
              field: "edit",
              sort: "asc",
              width: 50,
            },

            // {
            //   label: "Delete",
            //   field: "delete",
            //   sort: "asc",
            //   width: 50,
            // },
          ],
          rows: this.props.visa.visatypes.map((row) => {
            return {
              ...row,
              price: (
                <>
                  {" "}
                  {row.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
                    " MGA"}
                </>
              ),
              status: (
                <>
                  <Form.Switch
                    type="switch"
                    id="active"
                    style={{ marginLeft: "2em" }}
                    checked={row.status}
                    onChange={() => {
                      this.setState({
                        selectedVisa: row,
                        showModal: true,
                      });
                    }}
                  />
                </>
              ),
              edit: (
                <>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#e0ac1d",
                      color: "black",
                      fontSize: "0.25rem",
                    }}
                    onClick={() => {
                      window.open("/editvisa/" + row.id, "_blank");
                    }}
                  >
                    <Icon icon="pencil" />
                  </Button>
                </>
              ),
              // delete: (
              //   <>
              //     <Button
              //       id="delete"
              //       variant="contained"
              //       style={{ backgroundColor: "#B00020", color: "white" }}
              //       onClick={() => {
              //         this.handleDelete(row);
              //       }}
              //     >
              //       Delete
              //     </Button>
              //   </>
              // ),
            };
          }),
        },
      });
    }
  }

  render() {
    return (
      <div>
        <Container fluid>
          <Row>
            <Col lg="11">
              <h3>
                Active Visas :{" "}
                {this.props.visa.visatypes !== undefined &&
                this.props.visa.visatypes !== null &&
                this.props.visa.visatypes.length !== 0
                  ? this.props.visa.visatypes.filter((f) => f.status).length
                  : ""}
              </h3>
            </Col>
            <Col>
              <Button
                variant="primary"
                style={{ marginTop: "25px" }}
                onClick={() => {
                  window.open("/addvisa", "_blank");
                }}
              >
                Add
              </Button>
            </Col>
          </Row>
          {this.props.visa.loading ? (
            <Loading />
          ) : (
            <MDBDataTable
              cypressid="table"
              striped
              bordered
              hover
              responsive
              data={this.state.visatypesData}
            />
          )}
          <Modal
            style={{ opacity: 1 }}
            show={this.state.deleteModal}
            onHide={this.handleInputClose}
            animation={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are You Sure to Delete?{" "}
              {this.state.selectedVisa && this.state.selectedVisa.name_en}
            </Modal.Body>
            <Modal.Footer>
              <Button
                cypressid="cancel_delete"
                variant="secondary"
                onClick={this.handleInputClose}
              >
                Cancel
              </Button>
              <Button
                cypressid="confirm_delete"
                variant="contained"
                style={{ backgroundColor: "#B00020", color: "white" }}
                onClick={this.handleConfirm}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            style={{ opacity: 1 }}
            show={this.state.showModal}
            animation={false}
            onHide={this.handleInputClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are You Sure to Change Status of{" "}
              {this.state.selectedVisa &&
                this.state.selectedVisa.duration +
                  ", " +
                  this.state.selectedVisa.entry}
              ?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleInputClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: "#B00020", color: "white" }}
                onClick={this.handleConfirmStatus}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    firebaseauth: state.firebase.auth,
    visa: state.visa,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVisas: () => dispatch(GetVisas()),
    // deleteVisa: (id) => dispatch(DeleteVisa(id)),
    updateVisaStatus: (id, decision) =>
      dispatch(UpdateVisaStatus(id, decision)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Visas);
