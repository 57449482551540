export const GetStats = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // make async call to the database
    try {
      dispatch(StartLoadingRate());
      const firestore = getFirestore();
      firestore
        .collection("stats")
        .get()
        .then((data) => {
          const datalist = [];
          data.forEach((element) => {
            datalist.push({
              ...element.data(),
            });
          });
          //   dispatch({
          //     type: "GET_STATS",
          //     payload: datalist,
          //   });
          firestore
            .collection("visatypes")
            .get()
            .then((data) => {
              const visalist = [];
              data.forEach((element) => {
                visalist.push({
                  applications: element.data().applications,
                  title: element.data().duration + ", " + element.data().entry,
                });
              });
              firestore
                .collection("hoteltypes")
                .get()
                .then((data) => {
                  const hotellist = [];
                  data.forEach((element) => {
                    hotellist.push({
                      applications: element.data().applications,
                      title: element.data().title,
                    });
                  });
                  dispatch({
                    type: "GET_STATS",
                    payload: {
                      stats: datalist,
                      visa: visalist,
                      hotel: hotellist,
                    },
                  });
                })
                .catch((e) => {
                  dispatch({ type: "GET_STATS_ERROR", payload: e });
                });
            })
            .catch((e) => {
              dispatch({ type: "GET_STATS_ERROR", payload: e });
            });
        })
        .catch((e) => {
          dispatch({ type: "GET_STATS_ERROR", payload: e });
        });
    } catch (err) {
      dispatch({ type: "GET_STATS_ERROR", payload: err });
    }
  };
};

export const StartLoadingRate = () => {
  return {
    type: "LOADING_STATS",
  };
};
