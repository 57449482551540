import React, { Component } from "react";

import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";

import Loading from "../widgets/Loading";
import {
  GetSingleHotel,
  UploadHotelPicture,
  AddHotel,
  UpdateHotel,
} from "../../store/actions/hotelActions";
import { isFileImage } from "../../functions/util";
import { toast } from "react-toastify";
class AddEditHotel extends Component {
  state = {
    image: null,
    changeimage: false,
    description: "",
    price: "",
    star: 4,
    title: "",
    id: "",
    submitted: true,
  };

  componentDidMount() {
    document.title = "Add/Update Hotel";
    if (this.props.match.params.id) {
      this.props.getSingleHotel(this.props.match.params.id);
    }
  }

  componentDidUpdate(p, c) {
    if (
      p.hotel.loading === true &&
      this.props.hotel.loading === false &&
      this.props.hotel.addUpdateHotel === false &&
      this.props.hotel.hotel !== null
    ) {
      this.setState({
        description: this.props.hotel.hotel.description,
        price: this.props.hotel.hotel.price,
        star: this.props.hotel.hotel.star,
        title: this.props.hotel.hotel.title,
        id:
          this.props.match.params.id !== undefined
            ? this.props.match.params.id
            : "",
        originalimage: this.props.hotel.hotel.image,
      });
    }
    if (
      this.state.submitted &&
      this.props.hotel.hotelimage !== "" &&
      p.hotel.hotelimage === ""
    ) {
      const hotel = {
        description: this.state.description,
        price: this.state.price,
        star: this.state.star,
        title: this.state.title,
        id: this.state.id,
        image: this.state.originalimage,
      };
      if (this.props.match.params.id === undefined) {
        hotel.image = this.props.hotel.hotelimage;
        this.props.addHotel(hotel);
      } else {
        if (this.state.changeimage) {
          hotel.image = this.props.hotel.hotelimage;
        }
        //window.alert("Update Aritcle ");
        this.props.updateHotel(this.props.match.params.id, hotel);
      }
    }
    if (
      p.hotel.addUpdateHotel === false &&
      this.props.hotel.addUpdateHotel === true
    ) {
      window.location = "/hotels";
    }
  }

  handleChange = (e) => {
    if (e.target.files[0]) {
      this.setState({
        image: e.target.files[0],
        changeimage: true,
      });
    }
  };

  cancelImage = () => {
    this.setState({
      changeimage: false,
      image: null,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const hotel = {
      description: this.state.description,
      price: this.state.price,
      star: this.state.star,
      title: this.state.title,
      id: this.state.id,
      image: this.state.originalimage,
    };
    if (!isFileImage(this.state.image) && this.state.originalimage === "") {
      toast.error("File is Not Appropriate!");
    } else {
      if (this.props.match.params.id !== undefined) {
        hotel.id = this.props.match.params.id;
        if (this.state.changeimage) {
          this.props.uploadHotelPicture(this.state.image);
        } else {
          //window.alert("Update Aritcle No Image");

          this.props.updateHotel(this.props.match.params.id, hotel);
        }
      } else {
        this.props.uploadHotelPicture(this.state.image);
      }
    }
  };
  render() {
    return (
      <Container fluid>
        <Row>
          {this.props.match.params.id !== undefined ? (
            <h3>Edit Hotel</h3>
          ) : (
            <h3>Add Hotel</h3>
          )}
        </Row>
        <Row>
          <Col lg="12">
            {this.props.hotel.loading === true ? (
              <Loading />
            ) : (
              <Form className="mt-3 " onSubmit={this.handleSubmit}>
                <Container>
                  <Row>
                    <Col lg={8}>
                      <Form.Group>
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          type="text"
                          pattern="^(?=.*[a-zA-Z]).{5,50}$"
                          value={this.state.title}
                          onChange={(val) => {
                            this.setState({
                              title: val.target.value.toUpperCase(),
                            });
                          }}
                          placeholder="Enter Title"
                        />
                      </Form.Group>

                      <br />
                      <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          cypressid="content"
                          pattern="^(?=.*[a-zA-Z]).{5,50}$"
                          as="textarea"
                          value={this.state.description}
                          onChange={(val) => {
                            this.setState({
                              description: val.target.value,
                            });
                          }}
                          style={{ height: "10vh" }}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={4}>
                      <Form.Group>
                        <Form.Label>Stars</Form.Label>
                        <Form.Control
                          type="number"
                          minLength={1}
                          max={7}
                          min={1}
                          value={this.state.star}
                          onChange={(val) => {
                            this.setState({
                              star: val.target.value,
                            });
                          }}
                          placeholder="Star"
                        />
                      </Form.Group>

                      <br />

                      <Form.Group>
                        <Form.Label>Price ($)</Form.Label>
                        <Form.Control
                          cypressid="content"
                          pattern="\$\d+(?:\.\d+)?"
                          value={this.state.price}
                          onChange={(val) => {
                            this.setState({
                              price: val.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                      <br />
                    </Col>
                  </Row>
                </Container>
                <br />
                <hr />
                <br />
                <Container>
                  <Row>
                    <Col>
                      {this.props.match.params.id === undefined ? (
                        <>
                          <Form.Group>
                            <Form.Label>Hotel Id</Form.Label>
                            <Form.Control
                              placeholder="Enter Id"
                              minLength={6}
                              value={this.state.id}
                              required
                              type="text"
                              onChange={(value) => {
                                this.setState({
                                  id: value.target.value,
                                });
                              }}
                            />
                          </Form.Group>
                          <br />
                        </>
                      ) : (
                        <>
                          <br />
                          <h5>HOTEL LINK: {this.state.id}</h5>
                          <br />
                        </>
                      )}
                    </Col>
                    <Col>
                      <Row>
                        <Col lg="6">
                          <Form.Group controlId="formFile">
                            <Form.Label>Insert </Form.Label>
                            <Form.Control
                              cypressid="image"
                              onChange={this.handleChange}
                              type="file"
                              accept="image/*"
                            />
                          </Form.Group>
                        </Col>
                        <Col lg="6" className="cancelimagecol">
                          <Button
                            onChange={this.cancelImage}
                            className="cancelimagebtn"
                            variant="warning"
                          >
                            Cancel Image
                          </Button>
                        </Col>
                      </Row>
                      <br />
                      <hr />
                      <br />
                      <Form.Group>
                        <Button
                          cypressid="submit"
                          variant="info"
                          type="submit"
                          size="large"
                          style={{ width: "100%" }}
                          //onClick={this.handleSubmit}
                        >
                          Submit
                        </Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </Container>
              </Form>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    firebaseauth: state.firebase.auth,
    hotel: state.hotel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSingleHotel: (id) => dispatch(GetSingleHotel(id)),
    uploadHotelPicture: (image) => dispatch(UploadHotelPicture(image)),
    addHotel: (doc) => dispatch(AddHotel(doc)),
    updateHotel: (id, doc) => dispatch(UpdateHotel(id, doc)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditHotel);
